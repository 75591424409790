import { FormHelperText, IconButton, InputAdornment, Modal, styled } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import imgs from "../../assets/images/imgs";
import {
    isRequired,
    isSamePassword,
    isValidPassword,
} from "../../utils/validator";
import { firstLogin, resetfirstLogin } from "../../state/actions/firstLoginAction";
import { useDispatch, useSelector } from "react-redux";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { toast } from "react-toastify";

function ChangePassword(props) {



    const [showPassword, setShowPassword] = useState(false);
    const [showconfirmpassword, setshowconfirmpassword] = useState(false);

    const { changePassword, onChangePasswordClose, userId } = props;

    const [formDataChangePass, setformDataChangePass] = useState({
        new_password: "",
        confirm_password: "",
    });
    const [formErrorsChangePass, setformErrorsChangePass] = useState({
        new_password: "",
        confirm_password: "",
    });
    const [isLoadingchangePass, setisLoadingchangePass] = useState(false);

    const dispatch = useDispatch();

    const firstlogin = useSelector((state) => state.firstlogin);

    const customModalStyle = {
        modal: {
            background: "#F5F9FF",
            width: "560px",
            borderRadius: "10px",
        },
    };

    const handleChangePassword = (e) => {

        const { name, value } = e.target;
        let error = "";
        if (name === "new_password") {
            error = isRequired(value, "password", true) || isValidPassword(value, true);
        } else if (name === "confirm_password") {
            error =
                isRequired(value, "password", true) ||
                isValidPassword(value, true) ||
                isSamePassword(formDataChangePass.new_password, value, true);
        }

        setformErrorsChangePass({
            ...formErrorsChangePass,
            [name]: error,
        });

        setformDataChangePass({
            ...formDataChangePass,
            [name]: value,
        });

    }

    const submitChangePassword = async (e) => {
        e.preventDefault();

        let errors = {};

        errors.new_password =
            isRequired(formDataChangePass.new_password, "new_password", true) ||
            isValidPassword(formDataChangePass.new_password);
        errors.confirm_password =
            isRequired(formDataChangePass.confirm_password, "confirm_password", true) ||
            isValidPassword(formDataChangePass.confirm_password, true) ||
            isSamePassword(
                formDataChangePass.new_password,
                formDataChangePass.confirm_password, true
            );

        setformErrorsChangePass(errors);

        if (!Object.values(errors).some((error) => error)) {
            await changePasswordRequest();
        }
    }

    const changePasswordRequest = () => {
        setisLoadingchangePass(true);
        const req = {
            confirm_password: formDataChangePass.new_password,
        }
        dispatch(firstLogin(userId, req));
    }

    useEffect(() => {
        const failedErrorMessage =
            "Oops, something went wrong. Please try again later.";
        if (firstlogin?.message?.length) {
            toast.success(firstlogin?.message, {
                position: toast.POSITION.TOP_CENTER,
            });
            setisLoadingchangePass(false);
            onChangePasswordClose();
        } else if (resetfirstLogin?.error?.length) {
            setisLoadingchangePass(false);
            toast.error(firstlogin?.error || failedErrorMessage, {
                position: toast.POSITION.TOP_CENTER,
            });
            dispatch(resetfirstLogin());
        }
    }, [firstlogin]);

    useEffect(() => {
        return () => {
            dispatch(resetfirstLogin());
        }
    }, [])

    return (
        <Modal
            open={changePassword}
            onClose={onChangePasswordClose}
            center
            styles={customModalStyle}
        >
            <div className="forget_modal_sec_new">
                <Link onClick={onChangePasswordClose} className="chen_back_left">
                    <i className="fas fa-chevron-left"></i>Back
                </Link>
                <h6>choose new password</h6>
                <img src={imgs.png.frgt_img} alt=" " />
                <div className="forget_modal_sec_new_body_sec">
                    <div className="set_passcode_sec">
                        <div className="set_passcode_sec_single">
                            <label>Set New Password</label>
                            <input
                                type={showPassword ? "text" : "password"}
                                name="new_password"
                                value={formDataChangePass.new_password}
                                onChange={handleChangePassword}
                                style={
                                    formErrorsChangePass.new_password
                                        ? { border: "1px solid red" }
                                        : null
                                }
                                placeholder="Enter new password"
                            />
                            <div className="set_passcode_sec_single_eye">
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() =>
                                            setShowPassword(
                                                (prevShowPassword) => !prevShowPassword
                                            )
                                        }
                                        // onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                    >
                                        {showPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            </div>
                        </div>
                        {formErrorsChangePass.new_password && (
                            <FormHelperText error>
                                <ul>
                                    {formErrorsChangePass.new_password.split(".").map((error) => (
                                        <li key={error}>{error}</li>
                                    ))}
                                </ul>
                            </FormHelperText>
                        )}
                        <div className="set_passcode_sec_single">
                            <label>Confirm New Password</label>
                            <input
                                type={showconfirmpassword ? "text" : "password"}
                                placeholder="Enter new password"
                                name="confirm_password"
                                onChange={handleChangePassword}
                                value={formDataChangePass.confirm_password}
                                style={
                                    formErrorsChangePass.confirm_password
                                        ? { border: "1px solid red" }
                                        : null
                                }
                            />
                                 <div className="set_passcode_sec_single_eye">
                                              <InputAdornment position="end">
                                                <IconButton
                                                  aria-label="toggle password visibility"
                                                  onClick={() =>
                                                    setshowconfirmpassword(
                                                      (prevShowPassword) => !prevShowPassword
                                                    )
                                                  }
                                                  // onMouseDown={handleMouseDownPassword}
                                                  edge="end"
                                                >
                                                  {showconfirmpassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                              </InputAdornment>
                                            </div>
                        </div>
                        {formErrorsChangePass.confirm_password && (
                            <FormHelperText error>
                                <ul>
                                    {formErrorsChangePass.confirm_password.split(".").map((error) => (
                                        <li key={error}>{error}</li>
                                    ))}
                                </ul>
                            </FormHelperText>
                        )}
                        <div className="email_add_btn">
                            <button
                                type="button"
                                onClick={submitChangePassword}
                                disabled={isLoadingchangePass}
                            >
                                Continue
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default ChangePassword