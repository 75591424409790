import React, { useEffect, useState } from "react";
import MaltipleAssignmentviewResult from "./MaltipleAssignmentviewResult";
import MaltipleAssignmentStartAssignment from "./MaltipleAssignmentStartAssignment";
import "./MaltipleAssignment.css";
import MaltipleAssignmentviewPopup from "./MaltipleAssignmentviewPopup";
import { getPracticeAssignments, resetPracticeAssignmentsList } from "../../state/actions/getPracticeAssignmentsAction"
import { getAssignmentsByLesson, resetAssignmentsList } from "../../state/actions/getAssignmentsByLessonAction";
import { useAuth } from "../../features/auth/AuthContext";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import PaginationView from "../PaginationView/PaginationView";
import { useDispatch, useSelector } from "react-redux";
import { createAssignmentStatus } from "../../state/actions/createAssignmentStatusAction";
import LoadingComponent from "../LoadingComponent/LoadingComponent";
import { toast } from "react-toastify";
import { wsURL } from "../../api/api";

function MaltipleAssignment({ lesson, course, rubrics, setassignmentCount }) {
    const navigate = useNavigate();
    const { token } = useAuth();
    const dispatch = useDispatch();
    const { lesson_id } = useParams();
    const location = useLocation();
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedAssignment, setSelectedAssignment] = useState(null);
    const [selectedAssignmentIndex, setSelectedAssignmentIndex] = useState(null);
    const [assignmentPopUp, setAssignmentPopUp] = useState(false);
    const [assignmentRubric, setAssignmentRubric] = useState(null);
    const [getPendingPracAssign, setgetPendingPracAssign] = useState([]);
    const { count, next, previous, totalPages, error, assignments, isLoading } = useSelector((state) => state.getassignmentsbyLesson);
    const globalState = useSelector((state) => state.globalState);
    const generatepracticeassignment = useSelector((state) => state.generatePracticeAssignment);
    const [ws, setWs] = useState(null);

    const practice_id = location?.state?.practice_id ?? null;
    const submission_id = location?.state?.submission_id ?? null;
    const studentId = globalState.student_details?.data?.student_id;



    const startAssignmentPopupHandler = (assignment, index) => {
        setSelectedAssignment(assignment);
        setSelectedAssignmentIndex(index);
        let rubricId = null
        if (assignment.rubric_id !== null) {
            for (let i = 0; i < rubrics.length; i++) {
                if (assignment.rubric_id === rubrics[i].rubric_id) {
                    rubricId = rubrics[i]
                    break;
                }
            }
        }


        // setAssignmentRubric(rubrics[index] ? rubrics[index] : null);
        setAssignmentRubric(rubricId);
        setAssignmentPopUp(!assignmentPopUp);
    };


    const startAssignmentHandler = (status = 3) => {
        const course_id = course?.course_id;
        const lesson_id = lesson?.lesson_id;
        const id = selectedAssignment?.assignment_id;

        const payload = {
            student_id: globalState.student_details.data.student_id,
            assignment_id: id,
            status: 3,
            completed_name: selectedAssignment.name,
            assignment_type: 1,
        };

        dispatch(createAssignmentStatus(payload, token));

        try {
            const state = {
                course: JSON.parse(JSON.stringify(course)),
                lesson: JSON.parse(JSON.stringify(lesson)),
                assignment: selectedAssignment,
                rubric: assignmentRubric ? JSON.parse(JSON.stringify(assignmentRubric)) : null,
                assignmentDtls: assignments?.length > 0 ? JSON.parse(JSON.stringify(assignments)) : null,
                type: 1,
            };

            navigate(`/course/${course_id}/lesson/${lesson_id}/assignment/${id}/preview`, { state });
        } catch (error) {
            console.error("Serialization error:", error);
        }
    };

    useEffect(() => {
        dispatch(getAssignmentsByLesson(lesson_id, currentPage, token));
    }, [currentPage])

    // useEffect(() => {
    //     setTimeout(() => {
    //         dispatch(getAssignmentsByLesson(lesson_id, currentPage, token));
    //     }, 15000);
    // }, []);

    useEffect(() => {
        setassignmentCount(assignments?.length)
        const assignArr = assignments?.filter((assign) => assign.ai_feedback_json === null || assign.assignment_json
            === null)
        if (assignArr.length > 0) {
            setTimeout(() => {
                dispatch(getAssignmentsByLesson(lesson_id, currentPage, token));
            }, 30000);
        }
    }, [assignments]);

    useEffect(() => {
        if (assignments?.length > 0) {
            for (let i = 0; i < assignments.length; i++) {
                dispatch(getPracticeAssignments(assignments[i].assignment_id, token));
            }
        }
    }, [assignments]);

    // useEffect(() => {
    //     if (getPendingPracAssign.length > 0) {
    //         setTimeout(() => {
    //             for (let i = 0; i < getPendingPracAssign.length; i++) {
    //                 dispatch(getPracticeAssignments(getPendingPracAssign[i].assignment_id, token));
    //             }
    //         }, 30000);
    //     }
    // }, [getPendingPracAssign])

    useEffect(() => {
        const newWebSocketURL = `${wsURL}ws/practice_assignment/${studentId}/?token=${token}`;
        let reconnectInterval = 1000; // Initial reconnect interval in ms
        const maxReconnectInterval = 30000; // Max reconnect interval
        let reconnectAttempts = 0; // Number of reconnect attempts
        const reconnectDecay = 1.5; // Rate of increase of the reconnect delay
        const maxReconnectAttempts = 3; // Maximum number of reconnect attempts

        if (practice_id && submission_id) {
            const socket = new WebSocket(newWebSocketURL);
            setWs(socket);

            socket.onopen = () => {
                console.log("WebSocket connected");
                reconnectAttempts = 0; // Reset reconnect attempts on successful connection
                reconnectInterval = 1000; // Reset reconnect interval
                socket.send(JSON.stringify({ practice_id, submission_id }));
            };

            socket.onmessage = (event) => {
                // console.log("WebSocket message received:", event.data);
                const data = JSON.parse(event.data);
                if (data.status === 200) {
                    if (data.data.practice_assignment_status === "Completed") {
                        console.log("WebSocket message received:", data);
                        dispatch(getPracticeAssignments(data.data.assignment_id, token));
                    }
                }
            };

            socket.onclose = () => {
                console.log("WebSocket disconnected");
                if (reconnectAttempts < maxReconnectAttempts) {
                    setTimeout(() => {
                        reconnectAttempts++;
                        socket.close(); // Close old sockets if not yet closed
                        socket = new WebSocket(newWebSocketURL); // Create a new socket and attempt to connect
                        reconnectInterval = Math.min(reconnectInterval * reconnectDecay, maxReconnectInterval);
                    }, reconnectInterval);
                } else {
                    console.log("Max reconnect attempts reached, will not attempt further reconnects.");
                }
            };

            socket.onerror = (error) => {
                console.error("WebSocket error:", error);
                socket.close();
            };

            return () => {
                if (socket.readyState === 1) {
                    socket.close();
                }
            };
        }
    }, [practice_id, submission_id]);

    useEffect(() => {
        return () => {
            dispatch(resetAssignmentsList());
        };
    }, []);

    return (
        <>
            <div className="maltiple-assignment-section">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12 col-md-12">
                            <div className="maltiple-assignment-wrapper">
                                <div className="maltiple-assignment-card-container">

                                    {isLoading ? <LoadingComponent
                                        loading_text="Educating the Future, Loading Now."
                                    />
                                        : assignments?.length > 0 ?
                                            assignments?.map((assignment, index) => (
                                                <div className="maltiple-assignment-card-folder">
                                                    <MaltipleAssignmentviewResult key={index} index={index} assignment={assignment} startAssignmentPopupHandler={startAssignmentPopupHandler} lesson={lesson} course={course} rubrics={rubrics} setgetPendingPracAssign={setgetPendingPracAssign} />
                                                </div>
                                            ))
                                            : (
                                                <p style={{ textAlign: "center", marginTop: "20px" }}>No assignments found.</p>
                                            )
                                    }
                                </div>
                                {assignmentPopUp && <MaltipleAssignmentviewPopup type={"Assignment"} startAssignmentPopupHandler={startAssignmentPopupHandler} startAssignmentHandler={startAssignmentHandler} rubric={assignmentRubric} assignment={selectedAssignment} index={selectedAssignmentIndex} />}
                            </div>
                            {assignmentPopUp && (
                                <MaltipleAssignmentviewPopup
                                    type={"Assignment"}
                                    startAssignmentPopupHandler={startAssignmentPopupHandler}
                                    startAssignmentHandler={startAssignmentHandler}
                                    rubric={assignmentRubric}
                                    assignment={selectedAssignment}
                                    index={selectedAssignmentIndex}
                                />
                            )}
                        </div>
                    </div>
                    {totalPages > 1 && (
                        <PaginationView
                            totalPages={totalPages}
                            next={next}
                            previous={previous}
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                        />
                    )}
                </div>
            </div>
        </>
    );
}

export default MaltipleAssignment;
