import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { FormControl, FormHelperText, TextField } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { Waveform } from "@uiball/loaders";
import { Tooltip } from "react-tooltip";
// import AOS from "aos";
import "aos/dist/aos.css";
import { format } from "date-fns";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ReactSVG } from "react-svg";
import { toast } from "react-toastify";
import api, { wsURL } from "../../../../../../api/api";
import Autocomplete from "@mui/material/Autocomplete";
import Datetime from "react-datetime";
import {
  Course_created_successfully_image,
  Course_description_inner_ai_image,
  Regenerate_course_image,
  close,
  restrichtion_icon
} from "../../../../../../assets/images/images";
import Calimg from "../../../../../../assets/images/calendar.png";
import LodingAnimation from "../../../../../../components/LodingAnimation/LodingAnimation";
import "../../../../../../components/LodingAnimation/LodingAnimation.css";
import OutlineFIleInput from "../../../../../../components/styledComponents/OutlineButton";
import { useAuth } from "../../../../../../features/auth/AuthContext";
import { updateGlobalState } from "../../../../../../state/global/slice";
import { compressImage } from "../../../../../../utils/CompressImage";
import { isRequired } from "../../../../../../utils/validator";
import CropProfileImageModal from "../../CropProfileImageModal";
import FilteredTextField from "../../../../../../components/FilteredInput/FilteredTextField";
import EditSubject from "../../../../../../components/EditSubject.js/EditSubject";
import DeletePopup from "../../../../../../components/DeletePopup/Deletepopup";
import { updateTeacherprofileDetails, resetTeacherProfile } from "../../../../../../state/actions/updateTeacherProfileAction";
import { resetPlatformFee, getPlatformFee } from "../../../../../../state/actions/getPlatformFeeAction";
import LoaderComponent from "../../../../../../components/LoaderComponent/LoaderComponent";

const CreateCourse = (props) => {
  const navigate = useNavigate();
  const { token } = useAuth();
  const globalState = useSelector((state) => state.globalState);
  const { platformfee } = useSelector((state) => state.platformFee);
  const updateTeacherProfile = useSelector((state) => state.updateTeacherProfile);
  const isTeacher = globalState.role === "Teacher";
  const dispatch = useDispatch();
  const updateState = (key, value) => {
    dispatch(updateGlobalState({ key: key, value: value }));
  };
  const [levelInputValue, setLevelInputValue] = useState("");
  const [toLevelInputValue, setToLevelInputValue] = useState("");
  const [showCoursesuccesfully, setShowCoursesuccesfully] = useState(false);
  const [createLoading, setCreateLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [imageUploading, setImageUploading] = useState(false);
  const [imageUploaded, setImageUploaded] = useState(false);
  const [uplodedImage, setUplodedImage] = useState(null);
  const [addNewSubjects, setaddNewSubjects] = useState(false);
  const [courseFormState, setCourseFormState] = useState(() => {
    // Attempt to load existing course data from local storage
    const savedCourseData = localStorage.getItem("courseFormState");
    try {
      const _savedCourseData = JSON.parse(savedCourseData);
      _savedCourseData.start_date = dayjs(_savedCourseData.start_date);

      _savedCourseData.end_date = dayjs(_savedCourseData.end_date);

      return savedCourseData
        ? _savedCourseData ?? {}
        : {
          name: "",
          subject: "",
          from_grade: "",
          to_grade: "",
          start_date: null,
          end_date: null,
          price: null,
          discount_percentage: "",
          description: "",
          image: "",
          image_url: "",
          image_type: "",
          teacher: globalState?.teacher_details?.data?.teacher_id,
          is_active: true,
        };
    } catch (error) {
      return {
        name: "",
        subject: "",
        from_grade: "",
        to_grade: "",
        start_date: null,
        end_date: null,
        price: null,
        discount_percentage: "",
        description: "",
        image: "",
        image_url: "",
        image_type: "",
        teacher: globalState?.teacher_details?.data?.teacher_id,
        is_active: true,
      };
    }
  });
  const [formErrors, setFormErrors] = useState({
    name: "",
    subject: "",
    from_grade: "",
    to_grade: "",
    start_date: "",
    end_date: "",
    description: "",
    image: "",
    image_url: "",
    image_type: "",
  });
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [src, setSrc] = useState(null);
  const [clickCount, setClickCount] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");
  const [discountedAmt, setdiscountedAmt] = useState();
  const [commission, setcommission] = useState();
  const [finalAmt, setfinalAmt] = useState();
  const subjectsKey = isTeacher ? "subject_names" : "interest_names";

  const [selectedImage, setSelectedImage] = useState(null);

  const discountArr = [
    5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100
  ]
  const transformedDiscountArr = discountArr.map((discount) => `${discount}%`);
  const onOpenModal = () => {
    setModalIsOpen(true);
  };

  const onCloseModal = (val) => {
    // setUplodedImage('')
    // setImageUploaded(false)
    // setSelectedImage(null);
    setChangeImageUploadText(val)
    setModalIsOpen(false);
  };

  const isValidStartDate = (current) => {
    const today = dayjs().startOf('day'); // Today at 00:00
    return current.isSameOrAfter(today); // Allow today and future dates
  };

  const isValidEndDate = (current) => {

    // Ensure there's a start date selected
    if (!courseFormState.start_date) {
      return false; // Disable all dates if no start date is selected
    }

    // Calculate the minimum end date as one day after the start date
    const minEndDate = dayjs(courseFormState.start_date).add(1, 'day');

    // Compare the current date being processed by the picker with the minimum end date
    // Convert 'current' to a dayjs object for comparison
    return dayjs(current).isAfter(minEndDate, 'day'); // 'isAfter' will ensure the date is at least the day after minEndDate
  };

  const setShowCourse = props.setShowCourse;
  const toggleCourse = props.toggleCourse;
  // Function to fetch teacher_id
  const fetchTeacherId = async () => {
    if (courseFormState.teacher === undefined || !courseFormState.teacher) {
      // Check if teacher_id is empty or undefined
      try {
        const res = await api.get("/auth/get-teacher/", {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Token ${token}`,
          },
        });
        if (res?.data?.status === 200) {
          // Set teacher_id in courseFormState
          updateState("teacher_details", res?.data ?? {});
          setCourseFormState((prev) => ({
            ...prev,
            teacher: res?.data?.data?.teacher_id,
          }));
          return true; // Successfully fetched teacher_id
        }
      } catch (error) {
        console.error("Failed to fetch teacher_id:", error);
      }
    } else {
      return true; // teacher_id is already available
    }
    return false; // Failed to fetch teacher_id
  };
  const createCourse = async () => {
    try {
      if (courseFormState.start_date) {
        // courseFormState.set('dob', dayjs(new Date(courseFormState.start_date)).format("YYYY-MM-DD"));
        courseFormState.start_date = dayjs(
          new Date(courseFormState?.start_date)
        ).format("YYYY-MM-DD");
      }

      if (
        courseFormState.end_date &&
        dayjs(courseFormState.end_date).isValid()
      ) {
        courseFormState.end_date = dayjs(
          new Date(courseFormState.end_date)
        ).format("YYYY-MM-DD");
      } else {
        courseFormState.end_date = "";
      }

      const response = await api.post(
        "/courses/create-course/",
        courseFormState,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Token ${token}`,
          },
        }
      );
      if (response.status === 200 || response.status === 201) {
        localStorage.removeItem("courseFormState");
        localStorage.removeItem("to_grade");
        localStorage.removeItem("from_grade");
        setShowCoursesuccesfully(true);
        setCreateLoading(false);

        // add timeout
        document.body.style.overflow = "auto";
        setTimeout(() => {
          setShowCoursesuccesfully(false);
          setShowCourse(false);

          const course = response.data?.data ?? {};
          navigate(`/course/${course.course_id}/view`, {
            state: {
              course,
              teacher_id: globalState?.teacher_details?.data?.teacher_id,
            },
          });
          document.body.style.overflow = "auto";
        }, 3000);
      } else {
        toast.error("Failed to create course.", {
          position: toast.POSITION.TOP_CENTER,
        });
        setCreateLoading(false);
      }
    } catch (error) {
      console.error("API Error:", error);

      toast.error("Failed to create course.", {
        position: toast.POSITION.TOP_CENTER,
      });

      setCreateLoading(false);
    }
  };
  // Fetch teacher_id on page load
  useEffect(() => {
    fetchTeacherId();
  }, []); // Empty dependency array means this useEffect runs once when component mounts

  useEffect(() => {
    // Save the course form state to local storage
    localStorage.setItem("courseFormState", JSON.stringify(courseFormState));
  }, [courseFormState]);

  useEffect(() => {
    // Retrieve from_grade value from local storage on component mount
    const storedGrade = localStorage.getItem("from_grade");
    if (storedGrade) {
      const grade = gradeArray.find(option => option.key === storedGrade);
      if (grade) {
        setCourseFormState({ ...courseFormState, from_grade: storedGrade });
        setLevelInputValue(grade.value); // Set Autocomplete input value
      }
    }
  }, []);

  useEffect(() => {
    // Store from_grade value in local storage whenever it changes
    localStorage.setItem("from_grade", courseFormState.from_grade);
  }, [courseFormState.from_grade]); // Dependency ensures this effect runs whenever from_grade changes


  useEffect(() => {
    // Retrieve to_grade value from local storage on component mount
    const storedToGrade = localStorage.getItem("to_grade");
    if (storedToGrade) {
      const grade = gradeArray.find(option => option.key === storedToGrade);
      if (grade) {
        setCourseFormState({ ...courseFormState, to_grade: storedToGrade });
        setToLevelInputValue(grade.value); // Set Autocomplete input value
      }
    }
  }, []);

  useEffect(() => {
    // Store to_grade value in local storage whenever it changes
    localStorage.setItem("to_grade", courseFormState.to_grade);
  }, [courseFormState.to_grade]); // Dependency ensures this effect runs whenever to_grade changes


  // Handle input change for form fields
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    let error = "";
    if (name === "name") {
      error = isRequired(value);
    } else if (name === "description") {
      error = isRequired(value);
    } else if (name === "from_grade") {
      error = isRequired(value);
    } else if (name === "to_grade") {
      error = isRequired(value);
    } else if (name === "subject") {
      error = isRequired(value);
    } else if (name === "start_date") {
      error = isRequired(value);
    } else if (name === "end_date") {
      error = isRequired(value);
    }
    setFormErrors({
      ...formErrors,
      [name]: error,
    });

    // setCourseFormState({...courseFormState,  [name]: value });
    setCourseFormState((prev) => ({ ...prev, [name]: value }));
  };
  const YYYYMMDD = (date) => {
    try {
      return format(new Date(date ?? ""), "yyyy-MM-dd");
    } catch (error) {
      return "";
    }
  };

  const handleInputChangedate = (name, value) => {
    let error = "";
    if (name === "start_date") {
      error = isRequired(YYYYMMDD(value));
    } else if (name === "end_date") {
      error = isRequired(YYYYMMDD(value));
    }
    setFormErrors({
      ...formErrors,
      [name]: error,
    });
    if (YYYYMMDD(value)) {
      setCourseFormState((prev) => ({
        ...prev,
        [name]: YYYYMMDD(value),
      }));
    }
  };


  const [changeImageUploadText, setChangeImageUploadText] = useState(false);

  // Handle file input
  const handleFileInput = async (event) => {
    setCourseFormState((prev) => ({ ...prev, image_type: "" }));
    // Show loader when file input is created
    setImageUploading(true);
    setSelectedImage(null);
    setImageUploaded(false)

    const file = event.target.files[0];
    if (file) {
      try {
        // Compress the image
        const compressedImage = await compressImage(file);

        setSelectedImage(URL.createObjectURL(compressedImage));
        onOpenModal();
        // setCourseFormState((prev) => ({
        //   ...prev,
        //   image: compressedImage,
        //   image_url: null,
        // }));

        setFormErrors({
          ...formErrors,
          image: "",
        });

        setCourseFormState((prev) => ({ ...prev, image_type: 2 }));
      } catch (error) {
        console.error("Image compression failed:", error);
        setSelectedImage(null);
        // Handle error and set form error
        setFormErrors({
          ...formErrors,
          image: "Image compression failed.",
        });
      } finally {
        // Hide loader when compression is complete
        setImageUploading(false);
      }
    } else {
      setSelectedImage(null);
      // Hide loader when no file is selected (cancel)
      setImageUploading(false);
    }
  };
  // const handleFormSubmit = async (role, formData) => {
  //   setSelectedRole(role);
  //   setTempFormData(formData); // Store form data temporarily
  //   setOpenDialog(true);
  // };
  const [openDialog, setOpenDialog] = useState(false);
  const [tempFormData, setTempFormData] = useState(null);
  const handleCancelClick = () => {
    setOpenDialog(true);
  };
  const cancelRegistration = () => {
    setOpenDialog(false);
    document.body.style.overflow = "auto";
    // Reset the selected role or handle cancellation
  };

  const confirmCancellation = () => {
    setOpenDialog(false);
    document.body.style.overflow = "auto";
    // Add logic to handle the cancellation of course creation here
    // For example, navigate back or reset the form state
    localStorage.removeItem("courseFormState");
    localStorage.removeItem("from_grade");
    localStorage.removeItem("to_grade");
    props.toggleCourse("auto");

    // navigate to a different page or perform other cleanup actions
  };

  // Declare socket using the useState hook
  const [getSocket, setSocket] = useState(null);
  const [isRegenerate, setIsRegenerate] = useState(false);
  const generatesImges = async () => {
    setChangeImageUploadText(false)
    if (clickCount >= 3) {
      setErrorMessage(
        "Your AI image generation has reached the maximum of three attempts."
      );
      return;
    }
    // console.warn(imageUploaded);
    setImageUploaded(false)
    setIsRegenerate(true);
    setSelectedImage(null);
    const formdata = {
      Course_name: courseFormState.name || "",
      Subject:
        subjectsArray.filter((grd) => grd.key === courseFormState.subject)[0]
          ?.value ?? "",
      Grade: `${gradeArray.filter(
        (grd) => grd.key === courseFormState.from_grade
      )[0]?.value ?? ""}-${gradeArray.filter(
        (grd) => grd.key === courseFormState.to_grade
      )[0]?.value ?? ""}`,
      Course_description: courseFormState.description || "",
    };

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
        // Add any other headers that are required and were present in the Postman request
      },
      timeout: 100000,
      // Add other Axios configurations as necessary
    };

    await api
      .post(`/ai/generate_course_image/`, formdata, config)
      .then((response) => {
        setImageUploaded(true)
        setSelectedImage(response.data.image_urls);
        setUplodedImage(response.data.image_urls)
        // console.warn(imageUploaded);
        // console.warn(selectedImage);
        setCourseFormState((prev) => ({
          ...prev,
          image_url: response.data.image_urls,
          image: null,
        }));
        setClickCount(clickCount + 1);
        setCourseFormState((prev) => ({ ...prev, image_type: 1 }));
        setIsRegenerate(false);
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          image: "",
          image_url: "",
        }));
      })
      .catch((error) => {
        setFormErrors({
          ...formErrors,
          image_url: "Couldn't generate image",
        });
        setCourseFormState((prev) => ({ ...prev, image_type: 1 }));
        setSelectedImage(null);
        setIsRegenerate(false);
      });
    setIsRegenerate(false);
  };
  // New WebSocket URL
  const timestamp = Date.now();

  const newWebSocketURL = `${wsURL}ws/course_descrption/${timestamp}/?token=${token}`;

  let combinedMessage = [];
  // Create the WebSocket connection when the component mounts
  useEffect(() => {
    // Create a WebSocket connection with the new URL
    const socket = new WebSocket(newWebSocketURL);
    // Set the socket state variable
    setSocket(socket);

    // Event handler for when the WebSocket connection is established
    socket.addEventListener("open", (event) => {
    });
    document.body.style.overflow = "auto !important";
    // Event handler for incoming messages from the WebSocket server
    socket.addEventListener("message", (event) => {
      try {
        const data = JSON.parse(event.data);

        if (data && data.message) {
          const message = data.message;

          combinedMessage.push(message);
          let status = false;
          let chatdatares = combinedMessage.join("");

          if (
            chatdatares.includes("<STARTOFTURN>") ||
            chatdatares.includes("<ENDOFTURN>")
          ) {
            chatdatares = chatdatares
              .replace(/<STARTOFTURN>/g, "")
              .replace(/<ENDOFTURN>/g, "");

            if (combinedMessage.join("").includes("<ENDOFTURN>")) {
              status = true;
              combinedMessage.length = 0;
              combinedMessage.push(chatdatares);
            }
          }

          setCourseFormState((prev) => {
            if (status) {
              combinedMessage.length = 0;
            }
            return {
              ...prev,
              description: chatdatares
                .replace("<STARTOFTURN>", "")
                .replace("<ENDOFTURN>", ""),
            };
          });

          if(message.includes("<ENDOFTURN>")){
            setLoading(false);
          }
          // setLoading(false);
          setFormErrors((prevErrors) => ({ ...prevErrors, description: "" }));
        } else {
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
      }
    });
    socket.addEventListener("close", (event) => {
      console.log("open");
    });
    socket.addEventListener("error", (error) => { });

    return () => {
      if (socket.readyState === 1) {
        // <-- This is important
        socket.close();
      }
    };
  }, []);

  let grade_details;
  try {
    grade_details = globalState.grade_details?.grade;
  } catch (error) { }

  const gradeArray =
    grade_details && grade_details
      ? Object.entries(grade_details).map(([key, value]) => ({ key, value }))
      : [];

  const teacherSubjects = globalState?.teacher_details?.data?.subjects;

  const subjectsArray = teacherSubjects
    ? Object.entries(teacherSubjects)
      .map(([key, value]) => ({ key, value }))
      .sort((a, b) => a.value.localeCompare(b.value))
    : [];

  subjectsArray.push({ key: "others", value: "Others" })

  const handleSubmit = async (e) => {
    e.preventDefault();
    let errors = {};

    errors.name = isRequired(courseFormState.name);
    errors.subject = isRequired(courseFormState.subject);
    errors.to_grade = isRequired(courseFormState.to_grade);
    errors.from_grade = isRequired(courseFormState.from_grade);
    errors.start_date = isRequired(courseFormState.start_date);
    //errors.end_date = isRequired(courseFormState.end_date);
    errors.description = isRequired(courseFormState.description);
    console.log('courseFormState.image_type',courseFormState.image_type);
    if (courseFormState.image_type === 1) {
      errors.image = isRequired(courseFormState.image_url);
    } else {
      errors.image = isRequired(courseFormState.image);
    }

    setFormErrors(errors);

    if (!Object.values(errors).some((error) => error)) {
      setCreateLoading(true);
      toast.dismiss();
      const isTeacherIdAvailable = await fetchTeacherId();
      if (isTeacherIdAvailable) {
        createCourse();
      } else {
        toast.error("Failed to get teacher details.", {
          position: toast.POSITION.TOP_CENTER,
        });
        setCreateLoading(false);
      }
    }
  };

  function EnhancedDescription({
    courseName,
    subject,
    from_grade,
    to_grade,
    keywords,
  }) {
    // Function to enhance the description  using the WebSocket connection

    const enhanceDescription = (e) => {
      e.preventDefault();
      setLoading(true);

      if (getSocket) {
        // Create a JSON payload to send over the WebSocket
        const payload = {
          course_name: courseName,
          subject:
            subjectsArray.filter((sub) => sub.key === subject)[0]?.value ?? "",
          start_grade:
            gradeArray.filter((grd) => grd.key === from_grade)[0]?.value ?? "",
          end_grade:
            gradeArray.filter((grd) => grd.key === to_grade)[0]?.value ?? "",
          keywords: keywords,
        };
        // Convert the payload to a JSON string
        const payloadJSON = JSON.stringify(payload);

        // Send the JSON data over the WebSocket connection
        getSocket.send(payloadJSON);
      }
      // Add functionality to enhance the description here
    };

    return (
      <button
        onClick={enhanceDescription}
        disabled={
          !courseFormState.name ||
          !courseFormState.subject ||
          !courseFormState.from_grade ||
          !courseFormState.to_grade ||
          !courseFormState.start_date
        }
        className="Course-description-inner-ai-feilds"
      >
        <div className="Course-description-inne r-ai-image">
          <img src={Course_description_inner_ai_image} alt="" />
        </div>
        <span>Enhance Course Description</span>
      </button>
    );
  }

  // useEffect(() => {
  //   AOS.init({
  //     duration: 500,
  //   });
  // }, []);
  const [open1, setopen1] = useState(false);
  const [open2, setopen2] = useState(false);
  function capitalizeFirstLetter(string) {
    return string.replace(/\b(\w)/g, s => s.toUpperCase());
  }
  const capitalizedOptions = subjectsArray.map(option => ({
    ...option,
    value: capitalizeFirstLetter(option.value)
  }));

  const [isAddToSubjetModelOpen, setIsAddToSubjetModelOpen] = useState(false);


  const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false);

  // Function to close the popup
  const handleClose = () => {
    setIsDeletePopupOpen(false);
  };

  const handleAddSubjects = () => {
    handleClose();
    const req = {
      subject_names: addNewSubjects
    }
    dispatch(updateTeacherprofileDetails(req, token));
  }

  useEffect(() => {
    if (courseFormState.subject === "others") {
      setIsAddToSubjetModelOpen(true)
    }
  }, [courseFormState.subject])

  useEffect(() => {
    if (updateTeacherProfile.profileDetails !== null) {
      updateState("teacher_details", updateTeacherProfile.profileDetails ?? {});
      toast.success("Subject Added Successfully.", {
        position: toast.POSITION.TOP_CENTER,
      });
      dispatch(resetTeacherProfile())
    }
  }, [updateTeacherProfile])

  useEffect(() => {
    let discountAmt = courseFormState.price - (courseFormState.price * courseFormState.discount_percentage) / 100;
    setdiscountedAmt(discountAmt.toFixed(2));
    let commission = (discountAmt * platformfee?.percentage) / 100;
    setcommission(commission.toFixed(2));
    let amountAfterComm = discountAmt - (discountAmt * platformfee?.percentage) / 100;
    setfinalAmt(amountAfterComm.toFixed(2))
  }, [courseFormState.price, courseFormState.discount_percentage, platformfee])

  useEffect(() => {
    dispatch(getPlatformFee(token));
  }, [])

  const resetEndDate = () => {
    setCourseFormState(prev => ({
      ...prev,
      end_date: null  // Reset to null or empty string depending on how your state is expected
    }));
  };

  const [isMonetizeEnabled, setIsMonetizeEnabled] = useState(false);

  const handleSwitchToggle = () => {
    setIsMonetizeEnabled(!isMonetizeEnabled);
  };


  return (
    <>
      <form
        action=""
        className="Create-Courses-section"
        onSubmit={handleSubmit}
      >
        <div className="Create-Courses-wrapper">
          <h2>Create Course</h2>
          <div className="Create-Course-inner-input-container">
            <div className="Create-Course-inner-input">
              <label>Course Name</label>
              <FormControl margin="none" fullWidth error={!!formErrors.name}>
                <TextField
                  name="name"
                  placeholder="Eg. SQL"
                  variant="outlined"
                  size="small"
                  value={courseFormState.name}
                  error={!!formErrors.name}
                  onChange={handleInputChange}
                  InputProps={{
                    sx: {
                      borderRadius: "12px",
                      backgroundColor: "#F5F5F5",
                      padding: "0px",
                    },
                  }}
                />
                <FormHelperText>{formErrors.name}</FormHelperText>
              </FormControl>
            </div>
            <div className="Create-Course-inner-input">
              <label>Subject</label>
              <FormControl margin="none" fullWidth error={!!formErrors.subject}>
                <Autocomplete
                  value={
                    subjectsArray.find(
                      (option) => option.key === courseFormState.subject
                    ) || null
                  }
                  onChange={(event, newValue) => {
                    // Update the form state with the key of the selected option
                    handleInputChange({
                      target: {
                        name: "subject",
                        value: newValue ? newValue.key : "",
                      },
                    });
                  }}

                  id="subjects-autocomplete"
                  options={capitalizedOptions}
                  getOptionLabel={(option) => option.value ? capitalizeFirstLetter(option.value) : ""}

                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="subject"
                      placeholder="E.g., SQL"
                      variant="outlined"
                      size="small"
                      error={!!formErrors.subject}
                      helperText={formErrors.subject}
                      InputProps={{
                        ...params.InputProps,
                        value: params.InputProps.value ? capitalizeFirstLetter(params.InputProps.value) : "",
                        sx: {
                          borderRadius: "12px",
                          backgroundColor: "#F5F5F5",
                          textTransform: "capitalize",
                        },
                      }}
                    />
                  )}
                  sx={{
                    "& .MuiAutocomplete-listbox": {
                      textTransform: "capitalize",
                    },
                  }}
                />
              </FormControl>
            </div>
            {/* <div className="Create-Course-inner-input">
              <label>Subject</label>
              <FormControl margin="none" fullWidth error={!!formErrors.subject}>
                <TextField
                  name="subject"
                  placeholder="Eg. SQL"
                  variant="outlined"
                  select
                  size="small"
                  value={courseFormState.subject}
                  error={!!formErrors.subject}
                  onChange={handleInputChange}
                  defaultValue={courseFormState.subject || ""}
                  InputProps={{
                    sx: {
                      borderRadius: "12px",
                      backgroundColor: "#F5F5F5",
                      textTransform: "capitalize",
                    },
                  }}
                >
                  {subjectsArray.map((option) => (
                    <MenuItem
                      key={option.key}
                      value={option.key}
                      sx={{ textTransform: "capitalize" }}
                    >
                      {option.value}
                    </MenuItem>
                  ))}
                </TextField>
                <FormHelperText>{formErrors.subject}</FormHelperText>
              </FormControl>
            </div> */}
            <div className="create-course-Grade-input-folder">
              {/* <div className="Create-Course-inner-input create-course-inner-input-grade">
                <label>Level</label>
                
                <FormControl
                  margin="none"
                  fullWidth
                  error={!!formErrors.from_grade}
                >
                  <TextField
                    name="from_grade"
                    placeholder="Eg. SQL"
                    variant="outlined"
                    select
                    size="small"
                    error={!!formErrors.from_grade}
                    value={courseFormState.from_grade}
                    onChange={handleInputChange}
                    defaultValue={courseFormState.from_grade || ""}
                    InputProps={{
                      sx: {
                        borderRadius: "12px",
                        backgroundColor: "#F5F5F5",
                      },
                    }}
                  >
                    {gradeArray.map((option) => (
                      <MenuItem
                        key={`gradeArray1${option.key}`}
                        value={option.key}
                      >
                        {option.value}
                      </MenuItem>
                    ))}
                  </TextField>
                  <FormHelperText>{formErrors.from_grade}</FormHelperText>
                </FormControl>
              </div> */}
              <div className="Create-Course-inner-input create-course-inner-input-grade">
                <label>Level</label>
                <FormControl
                  margin="none"
                  fullWidth
                  error={!!formErrors.from_grade}
                >
                  <Autocomplete
                    value={
                      gradeArray.find(
                        (option) => option.key === courseFormState.from_grade
                      ) || null
                    }
                    onChange={(event, newValue) => {
                      handleInputChange({
                        target: {
                          name: "from_grade",
                          value: newValue ? newValue.key : "",
                        },
                      });
                      setLevelInputValue(newValue ? newValue.value : "");
                    }}
                    inputValue={levelInputValue}
                    onInputChange={(event, newInputValue, reason) => {
                      // Update the inputValue state whenever the input changes
                      // Use the reason parameter to handle different reasons for the input change
                      if (reason === "input") setLevelInputValue(newInputValue);
                      else if (reason === "clear") setLevelInputValue(""); // Clear the input value when the clear action is triggered
                    }}
                    id="from-grade-autocomplete"
                    options={gradeArray}
                    getOptionLabel={(option) => option.value || ""}
                    renderInput={(params) => (
                      <TextField // Using TextField directly for simplicity; replace with FilteredTextField if necessary
                        {...params}
                        name="from_grade"
                        placeholder="E.g., 9"
                        variant="outlined"
                        size="small"
                        error={!!formErrors.from_grade}
                        helperText={formErrors.from_grade}
                        InputProps={{
                          ...params.InputProps,
                          sx: {
                            borderRadius: "12px",
                            backgroundColor: "#F5F5F5",
                          },
                        }}
                      />
                    )}
                    sx={{
                      "& .MuiAutocomplete-listbox": {
                        textTransform: "capitalize",
                      },
                    }}
                  />
                </FormControl>
              </div>
              {/* <div className="Create-Course-inner-input create-course-inner-input-grade">
                <label>To Level</label>
                <FormControl
                  margin="none"
                  fullWidth
                  error={!!formErrors.to_grade}
                >
                  <TextField
                    name="to_grade"
                    placeholder="Eg. SQL"
                    variant="outlined"
                    select
                    size="small"
                    error={!!formErrors.to_grade}
                    value={courseFormState.to_grade}
                    onChange={handleInputChange}
                    defaultValue={courseFormState.to_grade || ""}
                    InputProps={{
                      sx: {
                        borderRadius: "12px",
                        backgroundColor: "#F5F5F5",
                      },
                    }}
                  >
                    {gradeArray
                      .filter(
                        (option) =>
                          Number(option.key) >=
                          Number(courseFormState.from_grade)
                      )
                      .map((option) => (
                        <MenuItem
                          key={`gradeArray2${option.key}`}
                          value={option.key}
                        >
                          {option.value}
                        </MenuItem>
                      ))}
                  </TextField>
                  <FormHelperText>{formErrors.to_grade}</FormHelperText>
                </FormControl>
              </div> */}
              <div className="Create-Course-inner-input create-course-inner-input-grade">
                <label>To Level</label>
                <FormControl
                  margin="none"
                  fullWidth
                  error={!!formErrors.to_grade}
                >
                  <Autocomplete
                    value={
                      // This should be the object that is currently selected or null if nothing is selected
                      gradeArray.find(
                        (option) => option.key === courseFormState.to_grade
                      ) || null
                    }
                    onChange={(event, newValue) => {
                      // This will update the parent form's state to reflect the selected value
                      handleInputChange({
                        target: {
                          name: "to_grade",
                          value: newValue ? newValue.key : "",
                        },
                      });
                      // This will clear the input allowing for a new value to be typed in
                      setToLevelInputValue(newValue ? newValue.value : "");
                    }}
                    inputValue={toLevelInputValue}
                    onInputChange={(event, newInputValue, reason) => {

                      if (reason === "input") {
                        // Update the inputValue state only when the user is typing
                        setToLevelInputValue(newInputValue);
                      } else if (reason === "clear") {
                        // Clear the input value when the clear action is triggered or after a selection is made
                        setToLevelInputValue("");
                      }
                    }}
                    id="to-grade-autocomplete"
                    options={gradeArray.filter(
                      (option) =>
                        Number(option.key) >= Number(courseFormState.from_grade)
                    )}
                    getOptionLabel={(option) => option.value || ""}
                    isOptionEqualToValue={(option, value) =>
                      option.key === value.key
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="to_grade"
                        placeholder="E.g., 12"
                        variant="outlined"
                        size="small"
                        error={!!formErrors.to_grade}
                        helperText={formErrors.to_grade}
                        InputProps={{
                          ...params.InputProps,
                          sx: {
                            borderRadius: "12px",
                            backgroundColor: "#F5F5F5",
                          },
                        }}
                      />
                    )}
                    sx={{
                      "& .MuiAutocomplete-listbox": {
                        textTransform: "capitalize",
                      },
                    }}
                  />
                </FormControl>
              </div>
            </div>
            <div className="Create-Course-inner-input" id="courseDate">
              <label>Course Start Date</label>
              <FormControl
                margin="none"
                fullWidth
                error={!!formErrors.start_date}
              >
                {/* <img src={Calimg} className="cal_img_sec" alt="" /> */}
                {/* Date Time New Picker Starts*/}
                {/* <Datetime
                  isValidDate={isValidStartDate}
                  timeFormat={false}
                  inputProps={{
                    placeholder: "MM:DD:YYYY",
                    readOnly: true,
                  }}
                  closeOnSelect={true}
                  dateFormat="MMM DD, YYYY"
                  placeholder="E.g. Sep 10, 2023"
                  name="start_date"
                  value={
                    courseFormState.start_date
                      ? dayjs(courseFormState.start_date).format(
                        "MMM DD, YYYY"
                      )  : ""
                  }
                  onChange={(date) =>
                    handleInputChangedate("start_date", date)
                  }
                  className="custom-datetime"
                /> */}
                {/* Date Time New Picker Ends */}
                <DesktopDatePicker
                  name="start_date"
                  variant="outlined"
                  disablePast
                  value={dayjs(courseFormState.start_date)}
                  error={!!formErrors.start_date}
                  onChange={(date) => handleInputChangedate("start_date", date)}
                  slots={{ openPickerIcon: CalendarMonthIcon }}
                  open={open1}
                  onClose={() => setopen1(false)}
                  onOpen={() => setopen1(true)}
                  renderInput={(params) => {
                    return <TextField {...params} />;
                  }}
                  slotProps={{
                    field: { setopen1 },
                    inputAdornment: {
                      position: "start",
                    },
                    textField: {
                      onClick: () => setopen1(true),
                      size: "small",
                      InputProps: {
                        sx: {
                          borderRadius: "12px",
                          backgroundColor: "#F5F5F5",
                        },
                      },
                    },
                  }}
                />
                <FormHelperText>{formErrors.start_date}</FormHelperText>
              </FormControl>
            </div>
            <div className="Create-Course-inner-input create-course-end-date" id="courseDate">
              <label>
                Course End Date <span>(Optional)</span>
              </label>
              {courseFormState.end_date && (
                <i onClick={() => resetEndDate()} className="fa-solid fa-x"></i>
              )}
              <FormControl
                margin="none"
                fullWidth
              // error={!!formErrors.end_date}
              >
                {/* <img src={Calimg} className="cal_img_sec" alt="" /> */}
                {/* Date Time New Picker Starts*/}
                {/* <Datetime
                  isValidDate={isValidEndDate}
                  timeFormat={false}
                  inputProps={{
                    placeholder: "MM:DD:YYYY",
                    readOnly: true,
                  }}
                  closeOnSelect={true}
                  dateFormat="MMM DD, YYYY"
                  placeholder="E.g. Sep 10, 2023"
                  name="end_date"
                  value={
                    courseFormState.end_date
                      ? dayjs(courseFormState.end_date).format(
                        "MMM DD, YYYY"
                      )
                      : ""
                  }
                  onChange={(date) =>
                    handleInputChangedate("end_date", date)
                  }
                  className="custom-datetime"
                /> */}
                {/* Date Time New Picker Ends*/}
                <DesktopDatePicker
                  name="end_date"
                  disablePast
                  // variant="outlined"
                  minDate={dayjs(courseFormState?.start_date ?? "")?.add(
                    1,
                    "day"
                  )}
                  value={dayjs(courseFormState.end_date)}
                  // error={!!(formErrors.end_date && courseFormState.end_date)}
                  onChange={(date) => handleInputChangedate("end_date", date)}
                  slots={{ openPickerIcon: CalendarMonthIcon }}
                  open={open2}
                  onOpen={() => setopen2(true)}
                  onClose={() => setopen2(false)}
                  renderInput={(params) => {
                    return <TextField {...params} />;
                  }}
                  slotProps={{
                    field: { setopen2 },
                    inputAdornment: {
                      position: "start",
                    },
                    textField: {
                      onClick: () => setopen2(true),
                      size: "small",
                      InputProps: {
                        sx: {
                          borderRadius: "12px",
                          backgroundColor: "#F5F5F5",
                        },
                      },
                    },
                  }}
                />
                {/* <FormHelperText>{formErrors.end_date}</FormHelperText> */}
              </FormControl>
            </div>
            { !globalState.subdomain_exists &&  <div className="course-toggle-uplode-folder">
              <div className="monetize-course-input-toggle">
                <div className="restriction-iocn">
                  <label>Monetize Course</label> 
                  <img src={restrichtion_icon} data-tooltip-id="monetize-course" />
                </div>
                <span className="monetize-course-input-toggle-folder">
                  <p>{isMonetizeEnabled ? 'On' : 'Off'}</p>
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={isMonetizeEnabled}
                      onClick={handleSwitchToggle}
                    />
                    <span className="slider round"></span>
                  </label>
                </span>
                <Tooltip
                  id="monetize-course"
                  place="top"
                  content={"Enable this toggle to display course monetization options."}
                  type="light"
                  effect="float"
                />
              </div>
            </div>
            }
          </div>
          
          {
            isMonetizeEnabled && !globalState.subdomain_exists && <div className="Create-Course-disscount-wrapper">
              <div className="Create-Course-inner-input">
                <label>Course Price ($)</label>
                <FormControl margin="none" fullWidth error={!!formErrors.price}>
                  <TextField
                    type="number"
                    name="price"
                    placeholder="Eg. Free or any amount"
                    variant="outlined"
                    size="small"
                    value={courseFormState.price}
                    error={!!formErrors.price}
                    onChange={handleInputChange}
                    InputProps={{
                      sx: {
                        borderRadius: "12px",
                        backgroundColor: "#F5F5F5",
                        padding: "0px",
                      },
                    }}
                  />
                  <FormHelperText>{formErrors.price}</FormHelperText>
                </FormControl>
              </div>
              <div className="Create-Course-inner-input">
                <label>Course Discount %</label>
                <FormControl margin="none" fullWidth error={!!formErrors.discount_percentage}>
                  <Autocomplete
                    // value={
                    //   courseFormState.discount_percentage
                    // }
                    value={courseFormState.discount_percentage ? `${courseFormState.discount_percentage}%` : courseFormState.discount_percentage}
                   
                    onChange={(event, newValue) => {
                      // Update the form state with the selected discount percentage
                      const numericValue = newValue ? String(newValue).replace('%', '') : "";
                        handleInputChange({
                          target: {
                            name: "discount_percentage",
                            value: numericValue, // Assuming newValue is the selected discount percentage
                          },
                        });
                      }}
                    id="discount_percentage"
                    options={transformedDiscountArr}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="discount_percentage"
                        placeholder="Eg. 10"
                        variant="outlined"
                        size="small"
                        error={!!formErrors.discount_percentage}
                        helperText={formErrors.discount_percentage}
                        InputProps={{
                          ...params.InputProps,
                          // value: params.InputProps.value ? capitalizeFirstLetter(params.InputProps.value) : "",
                          sx: {
                            borderRadius: "12px",
                            backgroundColor: "#F5F5F5",
                            textTransform: "capitalize",
                          },
                        }} 
                      />
                    )}
                    sx={{
                      "& .MuiAutocomplete-listbox": {
                        textTransform: "capitalize",
                      },
                    }}
                  />
                </FormControl> 
              </div>
              <div className="Create-Course-inner-input">
                <div className="restriction-iocn">
                  <label>Price After Discount ($)</label> 
                  <img src={restrichtion_icon} data-tooltip-id="discount-price" />
                </div>
                <span className="course-discount-input">
                  <p> $ {discountedAmt}</p>
                </span>
                <Tooltip
                  id="discount-price"
                  place="top"
                  content={
                    <>
                      Course Price after discount will be{' '}
                      <span style={{ textDecoration: 'line-through' }}>
                        ${courseFormState.price ? courseFormState.price : 0}
                      </span>{' '}
                      ${discountedAmt ? discountedAmt : 0}
                    </>
                  }
                  type="light"
                  effect="float"
                />
              </div>
              <div className="Create-Course-inner-input">
                <div className="restriction-iocn">
                  <label>Platform Fee</label>
                  <img src={restrichtion_icon} data-tooltip-id="course-platform-fee" />
                </div>
                <span className="course-discount-input">
                  <p>$ {commission}</p>
                </span>
                <Tooltip
                  id="course-platform-fee"
                  place="top"
                  // content={`Our platform applies a ${platformfee?.percentage}% commission fee on course purchased.`}
                  content={`Note: ProSchool charges a ${platformfee?.percentage}% commission per course sale to support platform enhancement and operation.`}
                  type="light"
                  effect="float"
                />
              </div>
              <div className="Create-Course-inner-input">
                <div className="restriction-iocn">
                  <label>What you get</label>
                  <img src={restrichtion_icon} data-tooltip-id="course-What-you-get" />
                </div>
                <span className="course-discount-input">
                  <p>$ {finalAmt}</p>
                </span>
                <Tooltip
                  id="course-What-you-get"
                  place="top"
                  // content="Taxes & applicable fees shall be deducted depending on local taxes."
                  content="Note: Taxes and additional fees will be subtracted from the final payout amount."
                  type="light"
                  effect="float"
                />
              </div>
            </div>
          }
          {/* Hidden for subdomain */}
          <div className="Create-Course-des-wrapper">
            <div className="Create-Course-des-folder">
              <div className="Create-Course-des-title">
                <label>Course description</label>
              </div>
              <FormControl
                margin="none"
                fullWidth
                error={!!formErrors.description}
              >
                <TextField
                  id="create-course-description"
                  name="description"
                  multiline
                  variant="outlined"
                  size="small"
                  margin="normal"
                  cols="30"
                  rows="9"
                  value={courseFormState.description || ""}
                  placeholder="Enter a short description about your course "
                  error={!!formErrors.description}
                  onChange={handleInputChange}
                  InputProps={{
                    sx: {
                      borderRadius: "12px",
                      backgroundColor: "#F5F5F5",
                    },
                  }}
                  inputProps={{
                    maxLength: 500, // Set the maximum length of the input
                  }}
                />
                <FormHelperText>{formErrors.description}</FormHelperText>
              </FormControl>
              {loading ? <Waveform /> : <></>}
              <div className="create-course-enhance-course-folder">
                <div className="Course-description-inner-ai-feilds-folder">
                  <EnhancedDescription
                    courseName={courseFormState.name || ""}
                    subject={courseFormState.subject || ""}
                    from_grade={courseFormState.from_grade || ""}
                    to_grade={courseFormState.to_grade || ""}
                    keywords={courseFormState.description || ""}
                    error={!!formErrors.description}
                  />
                </div>
                <div className="Create-Course-btn-wrapper">
                  <button
                    type="button"
                    className="Create-Course-cancle-btn"
                    onClick={handleCancelClick}
                    data-aos="zoom-out"
                  >
                    Cancel
                  </button>

                  <button
                    type="submit"
                    disabled={
                      createLoading ||
                      Object.values(formErrors).some((error) => error)
                    }
                    className="Create-Courses-btn"
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      minWidth: '150px',  // Minimum width to accommodate the largest content
                      height: '35px',  // Fixed height for consistency
                      backgroundColor: '#007BFF',  // Example color, adjust as needed
                      color: 'white',  // Text color
                      border: 'none',
                      cursor: 'pointer',
                      padding: '0 20px'
                    }}
                  >
                    {createLoading ? <LoaderComponent /> : "Create Course"}
                    {/* Create Course */}
                  </button>
                </div>
              </div>
              <div className="limiterrorMessage">
                {errorMessage && <span>{errorMessage}</span>}
              </div>
            </div>
            <div className="uploded-image-section">
              <FormControl margin="none" fullWidth error={!!formErrors.image}>
                <div className="Create-Course-image">
                  <div className="Create-Course-image-disclamer">
                    <p>Course Image</p>

                    {/* <span>
                      (Heads Up: Our Image Generator is in Beta and Might Have
                      Its Quirks!)
                    </span> */}
                  </div>

                  {
                    // console.warn(uplodedImage + ' selectedImage')
                    // console.warn()
                  }
                  {uplodedImage ? (
                    <>
                      <div className="course-main-image">
                        <ReactSVG
                          className="course-image-close"
                          src={close}
                          onClick={() => {
                            setCourseFormState((prev) => ({
                              ...prev,
                              image: "",
                              image_url: "",
                            }));
                            setUplodedImage(null)
                            setImageUploaded(false)
                            setSelectedImage(null);
                            setChangeImageUploadText(false)
                          }}
                        />
                        <img
                          className="course-uploded-image"
                          src={imageUploaded ? selectedImage : uplodedImage}
                          alt=""
                        />
                      </div>
                    </>
                  ) : (
                    <div
                      className="course-file-image"
                      style={
                        !!formErrors.image
                          ? { border: "2px #d32f2f dashed" }
                          : null
                      }
                    />
                  )}

                  <div className="Create-Course-uplode-image-container">

                    {!uplodedImage && (
                      <>
                        <div className="Create-Course-uisng-ai">
                          {/* <p className="Beta">Beta</p> */}
                          {isRegenerate && (
                            <img
                              className={`loding ${isRegenerate ? "spinning" : ""
                                }`}
                              src={Regenerate_course_image}
                              alt=""
                            />
                          )}
                          <button
                            type="button"
                            onClick={generatesImges}
                            className={`course-Generating-AI-btn`}
                            disabled={
                              !courseFormState.name ||
                              !courseFormState.subject ||
                              !courseFormState.description ||
                              !courseFormState.from_grade ||
                              !courseFormState.start_date ||
                              isRegenerate
                            }
                          >
                            Generate using AI
                          </button>
                        </div>
                        <div className="Create-Course-uisng-ai uplode-image">
                          <OutlineFIleInput
                            label={"Upload Image"}
                            handleFileInput={handleFileInput}
                          />
                          {imageUploading && (
                            <img
                              className={` ${imageUploading ? "spinning" : ""}`}
                              src={Regenerate_course_image}
                              alt=""
                            />
                          )}
                        </div>
                      </>
                    )}








                    {/* {!selectedImage && (
                      <>
                        <div className="Create-Course-uisng-ai"> */}
                    {/* <p className="Beta">Beta</p> */}
                    {/* {isRegenerate && (
                            <img
                              className={`loding ${isRegenerate ? "spinning" : ""
                                }`}
                              src={Regenerate_course_image}
                              alt=""
                            />
                          )}
                          <button
                            type="button"
                            onClick={generatesImges}
                            className={`course-Generating-AI-btn`}
                            disabled={
                              !courseFormState.name ||
                              !courseFormState.subject ||
                              !courseFormState.description ||
                              !courseFormState.from_grade ||
                              !courseFormState.start_date ||
                              isRegenerate
                            }
                          >
                            Generate using AI
                          </button>
                        </div>
                        <div className="Create-Course-uisng-ai uplode-image">
                          <OutlineFIleInput
                            label={"Upload Image"}
                            handleFileInput={handleFileInput}
                          />
                          {imageUploading && (
                            <img
                              className={` ${imageUploading ? "spinning" : ""}`}
                              src={Regenerate_course_image}
                              alt=""
                            />
                          )}
                        </div>
                      </>
                    )} */}
                  </div>
                </div>
                {uplodedImage && (
                  <>
                    <div className="reuplode-image-folder">
                      <div className="Create-Course-uplode-outer-image" style={isRegenerate ? { pointerEvents: 'none', opacity: '0.5' } : {}}>
                        <OutlineFIleInput
                          label={changeImageUploadText ? 'Re-Upload Image' : "Upload Image"}
                          handleFileInput={(e) => {
                            setSelectedImage(null);
                            handleFileInput(e);
                          }}
                        />
                      </div>
                      <div className="Regenerate-Course-image">
                        <img
                          className={`Regenerate-image ${isRegenerate ? "spinning" : ""
                            }`}
                          src={Regenerate_course_image}
                          alt=""
                        />
                        <button
                          disabled={
                            !courseFormState.name ||
                            !courseFormState.subject ||
                            !courseFormState.description ||
                            !courseFormState.from_grade ||
                            !courseFormState.start_date ||
                            isRegenerate
                          }
                          onClick={generatesImges}
                          type="button"
                          className="Regenerate-course-btn"
                        >
                          {changeImageUploadText ? 'Generate' : 'Regenerate'}
                        </button>
                      </div>
                    </div>
                  </>
                )}
                {/* {selectedImage && (
                  <>
                    <div className="reuplode-image-folder">
                      <div className="Create-Course-uplode-outer-image">
                        <OutlineFIleInput
                          label={"Re-Upload Image"}
                          handleFileInput={(e) => {
                            setSelectedImage(null);
                            handleFileInput(e);
                          }}
                        />
                      </div>
                      <div className="Regenerate-Course-image">
                        <img
                          className={`Regenerate-image ${isRegenerate ? "spinning" : ""
                            }`}
                          src={Regenerate_course_image}
                          alt=""
                        />
                        <button
                          disabled={
                            !courseFormState.name ||
                            !courseFormState.subject ||
                            !courseFormState.description ||
                            !courseFormState.from_grade ||
                            !courseFormState.start_date ||
                            isRegenerate
                          }
                          onClick={generatesImges}
                          type="button"
                          className="Regenerate-course-btn"
                        >
                          Regenerate
                        </button>
                      </div>
                    </div>
                  </>
                )} */}
                <div
                  className="Create-Course-btn-wrapper"
                  id="Create-Course-cancle-btn-wrapper"
                >
                  <button
                    type="button"
                    className="Create-Course-cancle-btn"
                    onClick={handleCancelClick}
                    data-aos="zoom-out"
                  >
                    Cancel
                  </button>

                  <button
                    type="submit"
                    disabled={
                      createLoading ||
                      Object.values(formErrors).some((error) => error)
                    }
                    className="Create-Courses-btn"
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      minWidth: '150px',  // Minimum width to accommodate the largest content
                      height: '35px',  // Fixed height for consistency
                      backgroundColor: '#007BFF',  // Example color, adjust as needed
                      color: 'white',  // Text color
                      border: 'none',
                      cursor: 'pointer',
                      padding: '0 20px'
                    }}
                  >
                    {createLoading ? <LoaderComponent /> : "Create Course"}
                    {/* Create Course */}
                  </button>
                </div>
                <FormHelperText>{formErrors.image}</FormHelperText>
              </FormControl>
            </div>
          </div>
        </div>
      </form>

      {isDeletePopupOpen && (
        <DeletePopup
          title="Are you sure you want to add this subject"
          cancel="Back"
          submit="Submit"
          onclick={handleAddSubjects}
          onClose={handleClose} // Pass handleClose as a prop
        />
      )}

      {openDialog && (
        <div className="Course-created-successfully-main">
          <div className="Course-created-successfully-container">
            <div className="Course-created-successfully-folder">
              {/* <div className="Course-created-successfully-image">
                <img src={Course_created_successfully_image} alt="" />
              </div> */}
              <h2>Are you sure you want to cancel your course creation?</h2>
              <div className="Course-close-btn">
                <button type="submit" onClick={cancelRegistration}>
                  No
                </button>
                <button
                  type="submit"
                  onClick={confirmCancellation}
                  className="Course-close"
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {showCoursesuccesfully && (
        <div className="Course-created-successfully-main">
          <div className="Course-created-successfully-container">
            <div className="Course-created-successfully-folder">
              <div className="Course-created-successfully-image">
                <img src={Course_created_successfully_image} alt="" />
              </div>
              <h2>Course has been created successfully</h2>
            </div>
          </div>
        </div>
      )}

      {createLoading && <LodingAnimation />}

      {modalIsOpen && (
        <CropProfileImageModal
          src={selectedImage}
          setSrc={setSelectedImage}
          setSelectedImage={setSelectedImage}
          setImageUploaded={setImageUploaded}
          setUplodedImage={setUplodedImage}
          modalStatus={modalIsOpen}
          setModalClose={onCloseModal}
          fieldName={"image"}
          setFormState={setCourseFormState}
          formState={courseFormState}
          aspectRatio={4 / 3}
        />
      )}

      {isAddToSubjetModelOpen && (
        <EditSubject
          subject_title="Add the subjects you teach"
          modalStatus={isAddToSubjetModelOpen}
          subjectlist={Object.values(
            isTeacher
              ? Object.values(globalState.teacher_details?.data?.subjects)
              : Object.values(globalState.student_details?.data?.interest)
          )}
          setModalClose={() => {
            setIsAddToSubjetModelOpen(false)
            setCourseFormState((prev) => ({
              ...prev,
              subject: ""
            }));
          }}
          onClose={() => {
            setIsAddToSubjetModelOpen(false)
            setCourseFormState((prev) => ({
              ...prev,
              subject: ""
            }));
          }}
          onSubmit={(subjects) => {
            setaddNewSubjects(subjects?.join(","))
            setIsDeletePopupOpen(true);
            setCourseFormState((prev) => ({
              ...prev,
              subject: ""
            }));
            // setFormState((prev) => ({
            //   ...prev,
            //   [subjectsKey]: subjects,
            // }));
            //setIsAddToSubjetModelOpen(false);
          }}
        />
      )}
    </>
  );
};

export default CreateCourse;