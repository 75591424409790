import React, { useState, useEffect } from "react";
import { MuiOtpInput } from "mui-one-time-password-input";
import { FormHelperText, Modal, styled } from "@mui/material";
import api from "../api/api";
import { isRequired } from "../utils/validator";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
const MuiOtpInputStyled = styled(MuiOtpInput)`
  display: flex;
  gap: 30px;
  max-width: 650px;
  margin-inline: auto;
`;
export default function EmailVerification(props) {
  const [forgotPassFormStep, setForgotPassFormStep] = useState(2);
  const [isLoadingforgotPass, setisLoadingforgotPass] = useState(false);
  const [formDataEmailVerify, setformDataEmailVerify] = useState({
    code: "",
  });
  const [formErrorsForgotPass, setformErrorsForgotPass] = useState({
    code: "",
  });
  const [remainingTime, setRemainingTime] = useState(90); // 300 seconds = 5 minutes
  const [isResendDisabled, setIsResendDisabled] = useState(true);
  const handleOtpChange = (value) => {
    let error = "";

    error = isRequired(value, "code");

    setformErrorsForgotPass({
      ...formErrorsForgotPass,
      code: error,
    });

    setformDataEmailVerify({
      ...formDataEmailVerify,
      code: value,
    });
  };
  const { isModalOpen, onClose, email, password, signupUser } = props;
  useEffect(() => {
    const timer = setInterval(() => {
      setRemainingTime(prevTime => {
        if (prevTime <= 1) {
          clearInterval(timer);
          setIsResendDisabled(false);
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);

    return () => clearInterval(timer); // Cleanup
  }, []);
  const handleResendClick = () => {
    setRemainingTime(298);
    setIsResendDisabled(true);
    checkEmailVerfied();
  };
  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };
  const checkEmailVerfied = (e) => {
    onSendEmailForgotPass(email).then((res) => {
      if (res.status === 200) {
        toast.success("Verification code re-sent successfully");

      } else {
        toast.error('Something went wrong!');
      }
    });
  };
  const onSendEmailForgotPass = async (email) => {

    if (email) {
      return await api
        .post("auth/email-verification/", {
          email: email,
        })
        .then((res) => {
          return res;
        })
        .catch((err) => {
          return err?.response;
        });
    } else {
      return "Please enter valid email";
    }
  };

  const onValidateOtpForgotPass = async (otp) => {
    return await api
      .post("auth/otp-verification/", {
        email: email,
        otp: otp,
      })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err?.response;
      });
  };
  const [token, setToken] = useState(null);
  const handleCodeSubmit = (e) => {
    e.preventDefault();
    toast.dismiss();
    setisLoadingforgotPass(true);
    let err = isRequired(formDataEmailVerify.code, "code");
    if (err) {
      setformErrorsForgotPass({
        ...formErrorsForgotPass,
        code: err,
      });
      setisLoadingforgotPass(false);
      return;
    }
    onValidateOtpForgotPass(formDataEmailVerify.code).then((res) => {
      if (res.status === 200) {
        // toast.success("Code validated successfully");
        if (password !== '' || password !== null) {
          signupUser(email, password);
        }

        setToken(res?.data?.token);
        // setForgotPassFormStep(3);
        props.onClose();
        props.setIsverified(true);
        toast.success(res?.data?.message);
      } else {
        const err = res?.data?.message ?? "Failed to validate code";
        toast.error(err === "Invalid OTP." ? "Invalid verification code" : err);
      }
    });
    setisLoadingforgotPass(false);
  };

  const clearAllForgotPass = () => {
    onClose();
    //delay 1 second
    setTimeout(() => {
      setformDataEmailVerify({
        email: "",
        code: "",
      });
      setForgotPassFormStep(2);
    }, 500);
  };

  const customModalStyle = {
    modal: {
      background: "#F5F9FF",
      width: "560px",
      borderRadius: "10px",
    },
  };
  useEffect(() => {
    setForgotPassFormStep(2);
  }, []);

  const handleKey = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();  // Prevent form submission on Enter
      handleCodeSubmit(e);  // Trigger the 'Confirm code' button functionality
    }

  }
  return (
    <Modal
      open={isModalOpen}
      onClose={clearAllForgotPass}
      center
      styles={customModalStyle}
    >
      <div className="forget_modal_sec_new" onKeyDown={handleKey}>
        <Link onClick={clearAllForgotPass} className="chen_back_left">
          <i className="fas fa-chevron-left"></i>Back
        </Link>
        <h6>Email Verification</h6>
        {/* <img src={imgs.png.frgt_img} alt=" " /> */}
        <div className="forget_modal_sec_new_body_sec">
          {forgotPassFormStep === 2 && (
            <div className="verification_code">
              <label>Enter verification code</label>
              <MuiOtpInputStyled
                label="6-Digit Code"
                fullWidth
                margin="normal"
                variant="outlined"
                length={6}
                name="code"
                TextFieldsProps={{ size: "small" }}
                error={!!formErrorsForgotPass.code}
                value={formDataEmailVerify.code}
                onChange={handleOtpChange}
                sx={{ paddingBottom: '15px', gap: "2px" }}
              />
              {formErrorsForgotPass.code && (
                <FormHelperText error>
                  {formErrorsForgotPass.code}
                </FormHelperText>
              )}
              <span className="Remaning_timer">Remaining time: <span>{formatTime(remainingTime)} Min.</span></span>
              <div>
                <div className="email_add_btn">
                  <button type="button" onClick={handleCodeSubmit}>
                    Confirm code
                  </button>
                </div>
                <div className="email_add_btn">
                  <button
                    type="button"
                    onClick={handleResendClick}
                    disabled={isResendDisabled}
                    style={{
                      backgroundColor: isResendDisabled ? '#d3d3d3' : '#0068FF', // Replace 'primaryColor' with your desired active color
                      color: isResendDisabled ? '#a9a9a9' : 'white', // Adjust text color if needed
                      // Add other styling as necessary
                    }}
                  >
                    Resend code
                  </button>

                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
}
