// function isValidEmail(val) {
//     const errors = [];

//     val = val ? val.trim() : val;
//     // Convert to string explicitly (optional)
//     val = String(val);
//     console.log(val);
//     if (!val || typeof val !== 'string') {
//         errors.push("Email cannot be empty.");
//         return errors;
//     }
//     let regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
//     console.log(!regEmail.test(val));
//     if (!regEmail.test(val)) {
//         errors.push("Please enter a valid email address.");
//     }
//     return errors;
// }
// function isValidPassword(val) {
//     const errors = [];

//     if (!val || typeof val !== 'string') {
//         errors.push("Password cannot be empty.");
//         return errors;
//     }
//     if (val?.length < 8) {
//         errors.push("Password must be at least 8 characters long.");
//     }

//     if (!/[A-Z]/.test(val)) {
//         errors.push("Password must contain at least one uppercase letter.");
//     }

//     if (!/\d/.test(val)) {
//         errors.push("Password must contain at least one digit.");
//     }

//     if (!/[!@#$%^&*(),.?":{}|<>]/.test(val)) {
//         errors.push("Password must contain at least one special character.");
//     }

//     return errors;
// }

// function isRequired(val, label) {

//     console.log('isRequired', val, label);
//     const errors = [];
//     if (!val || (typeof val === 'string' && val.trim() === '')) {
//         errors.push(`Please enter valid ${label}.`);
//     }
//     if (val?.length < 1) {
//         errors.push(`Please enter valid ${label}.`);
//     }
//     return errors;
// }
// valid.js

import { isAfter } from "date-fns";
import moment from "moment";
import validator from "validator";

const bullet = "•";
function isRequired(value, label = "field", isBullet = false) {
  return value
    ? ""
    : label === "field"
    ? "• This field is required"
      : `Enter ${label}.`;
}

function checkifDateisAfterToday(date) {
  // date can be "" or null
  // date format is "YYYY-MM-DD" or "YYYY-MM-DD HH:MM:SS" or "YYYY-MM-DDTHH:MM:SSZ" or "YYYY-MM-DDTHH:MM:SS.SSSZ"
  // date should be creater than today date
  const errors = [];
  const today = moment().format("YYYY-MM-DD");
  const dateToCheck = moment(date).format("YYYY-MM-DD");
  if (dateToCheck < today) {
    errors.push("• Expired date");
  }
  return errors.length > 0 ? errors.join(" ") : "";
}

function isMaxChar(value, char = 1000) {
  const errors = [];
  if (value.length >= char) {
    errors.push("Description should not exceed 1000 characters.");
  }

  return errors.length > 0 ? errors.join(" ") : "";
}

function isAfterDate(start_date, end_date) {
  const errors = [];
  if (start_date === null) {
    errors.push("• Please select start date.");
  }
  if (end_date === null) {
    errors.push("• Please select end date.");
  }
  if (!isAfter(start_date, end_date)) {
    errors.push("• End date must be after start date.");
  }
  return errors.length > 0 ? errors.join(" ") : "";
}
// function isValidDate(date) {
//     const errors = [];
//     if (date === null) {
//         errors.push("Please select date.");
//     }
//     if (date < new Date()) {
//         errors.push("Please select valid date.");
//     }
//     return errors.length > 0 ? errors.join(" ") : "";
// }
function isValidEmail(email, isBullet = false) {
  const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return regex.test(email)
    ? ""
    : `${isBullet ? bullet : ""} Please enter valid email address.`;
}

const isValidDomain = (domain, isBullet = false) => {
  const re = /([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?$/;
  return re.test(domain)
    ? ""
    : `${isBullet ? bullet : ""} Please enter valid Url address.`;
};

function isValidPassword(password, isBullet = false) {
  const errors = [];

  if (password?.length < 8) {
    errors.push(
      `${isBullet ? bullet : ""} Password must be at least 8 characters long.`
    );
  }

  if (!/[A-Z]/.test(password)) {
    errors.push(
      `${
        isBullet ? bullet : ""
      } Password must contain at least one uppercase letter.`
    );
  }

  if (!/[a-z]/.test(password)) {
    errors.push(
      `${
        isBullet ? bullet : ""
      } Password must contain at least one lowercase letter.`
    );
  }

  if (!/\d/.test(password)) {
    errors.push(
      `${isBullet ? bullet : ""} Password must contain at least one digit.`
    );
  }

  if (!/[!@#$%^&*(),.?":{}|<>]/.test(password)) {
    errors.push(
      `${
        isBullet ? bullet : ""
      } Password must contain at least one special character.`
    );
  }

  return errors.length > 0 ? errors.join(" ") : "";
}
function isSamePassword(password, confirm_password, isBullet = false) {
  const errors = [];
  if (password !== confirm_password) {
    errors.push(
      `${isBullet ? bullet : ""}Password and confirm password must be same.`
    );
  }
  return errors.length > 0 ? errors.join(" ") : "";
}
function isValidMobileNumber(mobile_number, country_code) {
  const errors = [];
  const number = `+${country_code}${mobile_number}`;
  if (!validator.isMobilePhone(number)) {
    errors.push("Please enter valid mobile number.");
  }
  return errors.length > 0 ? errors.join(" ") : "";
}
// Validation function to check if the new password is different from the current password
const isDifferentFromCurrentPassword = (currentPassword, newPassword) => {
  const errors = [];
  if (currentPassword === newPassword) {
    errors.push("New password must be different from current password.");
  }
  return errors.length > 0 ? errors.join(" ") : "";
};
const isAlphanumeric  = (value) => {
  const errors = [];
  const isAlphanumeric = /^[a-z0-9]+$/i;
  if (!isAlphanumeric.test(value)) {
    errors.push("Please enter a valid subdomain name; it should only contain letters and numbers.");
  }
  return errors.length > 0 ? errors.join(" ") : "";
};

const parseKaTeX = (katex) => {
  // Extending the basic parser to handle more symbols and expressions
  let processedText = katex.replace(/\$\$/g, '');

  // Then proceed with replacing mathematical symbols and expressions
  processedText = processedText
    // Basic arithmetic and algebra
    .replace(/f\(([^)]+)\)/g, 'f of $1')
    .replace(/\\frac{([^}]+)}{([^}]+)}/g, '($1) divided by ($2)')
    .replace(/\\sqrt{([^}]+)}/g, 'the square root of ($1)')
    .replace(/\\times/g, ' multiplied by ')
    .replace(/\\div/g, ' divided by ')
    .replace(/\^2/g, ' squared')
    .replace(/\^3/g, ' cubed')
    .replace(/\^{([^}]+)}/g, ' raised to the power of $1')
    .replace(/\\left\(/g, '(')
    .replace(/\\right\)/g, ')')
    .replace(/&#39;/g, "'")
    // Additional symbols and functions
    .replace(/\\pi/g, 'pi')
    .replace(/\\theta/g, 'theta')
    .replace(/\\sigma/g, 'sigma')
    .replace(/\\lambda/g, 'lambda')
    .replace(/\\sum/g, 'sum of ')
    .replace(/\\int/g, 'integral of ')
    .replace(/\\infty/g, 'infinity')
    .replace(/\\pm/g, ' plus or minus ')
    .replace(/\\mp/g, ' minus or plus ')
    .replace(/\\cdot/g, ' dot ')
    .replace(/\\leq/g, ' less than or equal to ')
    .replace(/\\geq/g, ' greater than or equal to ')
    .replace(/\\neq/g, ' not equal to ')
    .replace(/\\approx/g, ' approximately equal to ')
    .replace(/\\rightarrow/g, ' implies ')
    .replace(/\\sin/g, 'sine of ')
    .replace(/\\cos/g, 'cosine of ')
    .replace(/\\tan/g, 'tangent of ')
    .replace(/\\log/g, 'logarithm of ')
    .replace(/\\ln/g, 'natural logarithm of ')
    .replace(/\\exp/g, 'exponential of ')
    .replace(/\\rightarrow/g, 'tends to ')
    .replace(/\\lim/g, 'limit ')
    .replace(/\+/g, ' plus ')
    .replace(/-/g, ' minus ')  
    // If you're also considering LaTeX's way of prime notation
    .replace(/\\prime/g, ' prime ');

    return processedText;
};

export {
  checkifDateisAfterToday, isAfterDate, isAlphanumeric, isDifferentFromCurrentPassword, isMaxChar,
  isRequired,
  isSamePassword, isValidDomain, isValidEmail, isValidMobileNumber, isValidPassword, parseKaTeX
};

