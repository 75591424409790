import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import sample_downloadFile from "../../../assets/download/sample_bulkupload.xlsx";
import { BulkUplode_bg_image } from "../../../assets/images/images";
import { useAuth } from "../../../features/auth/AuthContext";
import { addBulkProfile } from "../../../state/actions/addBulkProfileAction";
import { resetaddbulkProfile } from "../../../state/reducers/addBulkProfileReducer";
import UploadFile from "../../UploadFile/UploadFile";
import "./bulkUplode.css";
import FileDownloadDoneIcon from '@mui/icons-material/FileDownloadDone';

const BulkUplode = ({ toggleshowAddProfile, entity }) => {

    const [base64, setBase64] = useState(null);
    const [error, setError] = useState(false);

    const { token } = useAuth();
    const dispatch = useDispatch();
    const { school_id } = useSelector((state) => state.globalState);
    const addbulkprofile = useSelector((state) => state.addbulkProfile);

    const submitHandler = () => {
        if (base64 === null) {
            setError(true)
        }
        else {
            setError(false)
            bullProfileUploadRequest()
        }
    }

    const bullProfileUploadRequest = () => {
        const req = {
            school_id,
            role: entity === "Student" ? 1 : 2,
            file: base64,
        }

        dispatch(addBulkProfile(req, token));
    }

    useEffect(() => {
        const failedErrorMessage =
            "Oops, something went wrong. Please try again later.";

        if (addbulkprofile?.message?.length) {
            toast.success(addbulkprofile?.message, {
                position: toast.POSITION.TOP_CENTER,
            });
            toggleshowAddProfile();
        } else if (addbulkprofile?.error?.length) {
            toast.error(addbulkprofile?.error || failedErrorMessage, {
                position: toast.POSITION.TOP_CENTER,
            });
            dispatch(resetaddbulkProfile());
        }
    }, [addbulkprofile])

    useEffect(() => {
        return () => {
            dispatch(resetaddbulkProfile());
        };
    }, []);

    return (
        <>
            <div className="BulkUplode-section">
                <div className="BulkUplode-wrapper">
                    <div className="BulkUplode-image">
                        <img src={BulkUplode_bg_image} alt="" />
                    </div>
                    <div className="BulkUplode-button-wrapper">
                        {base64 !== null && <div>
                            <span className="File_Uploaded" style={{ color: "green", fontSize: "13px !important" }}><FileDownloadDoneIcon />
                                File Uploaded :</span>
                            <span className="fileName" style={{ color: "green", marginLeft: "8px" }}>
                                <p >{base64.name}</p>
                            </span>
                        </div>}
                        <a href={sample_downloadFile} download="sample_file.xlsx">
                            <div className="BulkUplode-button-folder">
                                <span><i class="fa-solid fa-table-cells"></i></span>
                                <p>Download Table format</p>
                            </div>
                        </a>
                        <UploadFile setBase64={setBase64} />
                        {base64 === null && error && <p style={{ color: "red" }}> Upload Excel File</p>}
                        <p style={{ color: 'black' }}>Upload a file in the Excel (.xlsx) format. Other formats are not supported.</p>
                    </div>
                </div>
                <div className="add-profile-btn-container">
                    <button type="button" className="add-profile-save-btn add-profile-close-btn" onClick={toggleshowAddProfile}>Close</button>
                    <button type="button" className="add-profile-save-btn" onClick={submitHandler}>Save</button>
                </div>
            </div>
        </>
    );
};
export default BulkUplode;
