import AOS from "aos";
import "aos/dist/aos.css";
import dayjs from "dayjs";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import api, { shareUrl } from "../../../../../../api/api";
import {
  Course_created_successfully_image,
  course_bg_image,
  social_image,
} from "../../../../../../assets/images/images";
import { addCourseToCart, resetAddToCart } from "../../../../../../state/actions/addtoCartAction";
import { Modal } from "react-responsive-modal";
import { useAuth } from "../../../../../../features/auth/AuthContext";
import { updateGlobalState } from "../../../../../../state/global/slice";
import { changeScrollPopup } from "../../../../../../utils/utlis";
import DescriptionCoursePopup from "../../teacher/course/DescriptionCoursePopup";
import StudentViewCourseLesson from "./StudentViewCourseLesson";
import LoadingComponent from "../../../../../../components/LoadingComponent/LoadingComponent";
import CancelPopup from "../../../../../../components/CancelPopup/CancelPopup";

const StudentViewCourseDetails = (props) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const location = useLocation();
  const { token } = useAuth();
  const dispatch = useDispatch();
  const [isEnrolled, setIsEnrolled] = useState(false);
  const [cancelstatus, setCancelstatus] = useState(false);
  const [showreuestsuccesfully, setshowreuestsuccesfully] = useState(false);
  const [lessons, setlessondetails] = useState(null);
  const [loading, setLoading] = useState(false);
  const [confirmAddtoCart, setconfirmAddtoCart] = useState(false);
  const [inCart, setInCart] = useState(location?.state?.course?.in_cart);
  const [course, setCourse] = useState(
    location?.state?.course ?? props?.course ?? {}
  );
  const [getcoursestatus, setCoursestatus] = useState(course?.status ?? "");
  const [selLession, setSelLession] = useState(-1);
  const [showDropdown, setShowDropdown] = useState(false);
  const [descriptionOpen, setDescriptionOpen] = useState(false);
  const [showViewMore, setShowViewMore] = useState(false);
  const [linkBaseUrl, setLinkBaseUrl] = useState("");
  const paragraphRef = useRef(null);
  const globalState = useSelector((state) => state.globalState);
  const addToCartData = useSelector((state) => state.addToCart);
  const currentLocation = useLocation();
// const [redirectToCart, setRedirectToCart] = useState(location?.state?.cart ? -1 : "/dashboard/home");



  const updateState = (key, value) => {
    dispatch(updateGlobalState({ key: key, value: value }));
  };


    useEffect(() => {
      if(course?.is_profile === false){  
              
        localStorage.setItem("redirectToCourse", currentLocation.pathname);
        navigate(`/dashboard/home`);
      }
    }, [course]);


  useEffect(() => {
    fetchCourse(id);
  }, [id]);

  const fetchCourse = async (id) => {
    const getCourse = await api
      .get(`courses/get-course/${id}/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      })
      .then((res) => res?.data?.data)
      .catch((err) => {
        console.log(err);
        return null;
      });
    if (!getCourse) {
      console.log("Error in getting course");
      return;
    }
    setCourse(getCourse);
    setInCart(getCourse.in_cart);
    setCoursestatus(getCourse?.status ?? "");
  };

  useEffect(() => {
    if (
      course?.course_id &&
      course?.course_id !== null &&
      course?.course_id !== ""
    ) {
      setLoading(true)
      api
        .get(`lessons/get-all-lessons/course/${course?.course_id}/`, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Token ${token}`, // `Token ${token}`
          },
        })
        .then((response) => {
          setLoading(false)
          if (response.status === 200) {
            setlessondetails(response?.data?.data);
          }
        })
        .catch((error) => {
          setLoading(false)
        });
    }
  }, [course?.course_id]);

  const onExpand = (i) => {
    setSelLession(selLession === i ? -1 : i);
  };

  const courseRequested = (id) => {
    if (course?.source && course?.source === "Requested") {
      toast.error("You have already requested enrollment for this course");
    } else {
      const formData = new FormData();
      formData.append("course", id);
      // Get current date
      const currentDate = new Date();

      // Convert to ISO string (YYYY-MM-DDTHH:MM:SS.sssZ) and slice to get YYYY-MM-DD format
      const formattedDate = currentDate.toISOString().slice(0, 10);

      formData.append("enrolled_on", formattedDate);
      const url = "courses/student/enroll-course/";
      const headers = {
        Authorization: `Token ${token}`,
      };
      api
        .post(url, formData, { headers })
        .then((response) => {
          if (response.data.status === 200) {
            setshowreuestsuccesfully(true);
            setTimeout(() => {
              setshowreuestsuccesfully(false);
              api
                .get("/courses/student/my-courses-dashboard/", {
                  headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: `Token ${token}`,
                  },
                })
                .then((response) => {
                  if (response.status === 200) {
                    updateState("student_courses", response?.data?.data ?? []);
                    setIsEnrolled(true);
                    setCoursestatus("Pending");
                  }
                })
                .catch((error) => {
                  //
                });
            }, 2000);
          }
        })
        .catch((error) => { });
    }
  };

  const getShareLink = ({ platform, course, baseURL }) => {
    let shareUrl = "";
    baseURL = `${baseURL}`; // Append /view to the baseURL
    const hostname = window.location.hostname;
    const data = `Hi! You're invited to enroll in the new course, ${course?.name} by ${course?.title} ${course?.teacher}. Check it out here:`;

    switch (platform) {
      case "facebook":
        shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
          baseURL
        )}&quote=${encodeURIComponent(data)}`;
        break;
      case "whatsapp":
        shareUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(
          data
        )} ${encodeURIComponent(baseURL)}`;
        break;
      case "twitter":
        shareUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(
          data
        )} ${encodeURIComponent(baseURL)}`;
        break;
      case "instagram":
        shareUrl = `https://www.instagram.com/?url=${encodeURIComponent(
          baseURL
        )}`;
        break;
      case "share":
        shareUrl = hostname;
        break;
      default:
        shareUrl = hostname;
        return;
    }
    return shareUrl;
  };

  const getPlatform = {
    FACEBOOK: "facebook",
    WHATSAPP: "whatsapp",
    TWITTER: "twitter",
    INSTAGRAM: "instagram",
    SHARE: "share",
  };

  const toggleDropdown = () => {
    onShare(getPlatform.SHARE, false);
    setShowDropdown(!showDropdown); // Toggle the visibility state
    changeScrollPopup("auto", "view-course-details-share-form");
  };

  const hostname = window.location.hostname;
  const Url = hostname;

  const onShare = async (platform, isOpenNewWindow = true) => {
    if (isOpenNewWindow) {
      toggleDropdown();
    }
    const getURL = `${Url}/course/${course?.course_id}/view`;
    const getShareableLink = getShareLink({
      platform: platform,
      course,
      baseURL: getURL,
    });
    setLinkBaseUrl(getURL);
    if (isOpenNewWindow) {
      window.open(getShareableLink, "_blank");
    }
  };

  const onClickCopy = () => {
    toggleDropdown();
    try {
      // Check if the Clipboard API is available
      if (navigator.clipboard && window.isSecureContext) {
        // Use the Clipboard API to copy the selected text to the clipboard
        navigator.clipboard
          .writeText(linkBaseUrl)
          .then(() => {
            toast.success("Copied to clipboard", {
              position: toast.POSITION.TOP_CENTER,
            });
          })
          .catch((error) => {
            toast.error("Failed to copy text", {
              position: toast.POSITION.TOP_CENTER,
            });
          });
      } else {
        // Fallback method for non-SSL environments
        document.execCommand("copy");
        toast.success("Copied to clipboard", {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    } catch (error) {
      toast.error("Failed to copy text", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  useEffect(() => {
    onShare(getPlatform.SHARE, false);
  }, []);

  useEffect(() => {
    AOS.init({
      duration: 500,
    });
  }, []);

  const cancelrequest = (courseid) => {
    const url = `courses/cancel-enrollment-request/${courseid}/`;
    const headers = {
      Authorization: `Token ${token}`,
    };
    api
      .delete(url, { headers })
      .then((response) => {
        if (response.data.status === 200) {
          setCancelstatus(true);
          setshowreuestsuccesfully(true);
          setTimeout(() => {
            setshowreuestsuccesfully(false);
            setOpenDialog(false);
            setCancelstatus(false);
            setCoursestatus(null);
            setIsEnrolled(false);
          }, 2000);
        }
      })
      .catch((error) => { });
  };

  const descriptionToggle = () => {
    setDescriptionOpen(!descriptionOpen);
  };

  useEffect(() => {
    const descriptionLength = course?.description?.length || 0;

    if (paragraphRef.current) {
      const lineHeight = parseFloat(
        window.getComputedStyle(paragraphRef.current).lineHeight
      );
      const height = paragraphRef.current.clientHeight;
      const lineCount = height / lineHeight;

      // Set the condition to show the 'View More' button for either more than 3 lines or more than 500 characters
      setShowViewMore(lineCount > 5 || descriptionLength > 1000);
    }
  }, [course?.description]);
  const [openDialog, setOpenDialog] = useState(false);

  const handleCancelClick = () => {
    setOpenDialog(true);
  };
  const cancelRegistration = () => {
    setOpenDialog(false);
    document.body.style.overflow = "auto";
    // Reset the selected role or handle cancellation
  };

  const addToCartPopup = () => {
    setconfirmAddtoCart(!confirmAddtoCart);
  }

  const addToCart = () => {
    setInCart(true)
    setconfirmAddtoCart(false);
    dispatch(addCourseToCart(course?.course_id, token));
    //navigate(`/cart`);
  };

  useEffect(() => {
    if (addToCartData?.message?.length) {
      toast.success(addToCartData?.message);
    }
    if (addToCartData?.error?.length) {
      toast.error(addToCartData?.error);
    }
    return () => {
      dispatch(resetAddToCart());
    };
  }, [addToCartData]);

  return (
    <>
      <div className="dashboard" id="StudentViewCourseDetails-section">
        <div className="view-course-Details-wrapper">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <div
                  className="view-course-Details-back-btn-folder"
                  onClick={() =>
                  {
                    if (location?.state?.redirectTo === "cart") {
                      navigate("/cart", { state: { course } })
                    } else {
                    navigate("/dashboard/home", { state: { course } })
                  }
                  }
                  }
                >
                  <i class="fa-solid fa-chevron-left"></i>
                  <h2>Back</h2>
                </div>

                <div className="studentDashboar-profile-btn-folder">
                  <div className="view-course-details-edit-and-share-container">
                    <div
                        onClick={toggleDropdown}
                        className="view-course-details-edit-and-share-folder view-course-details-share-btn"
                        id="student-share-btn"
                      >
                        <span href="#">
                          <i className="fa-solid fa-share-nodes"></i>
                        </span>
                        <p>Share</p>
                    </div>
                  </div>

                  {(!globalState?.subdomain_exists && (course?.discount_price !== null || course?.price !== null)) && (
                    <button
                      className="enroll_now_btn"
                      type="button"
                      style={{
                        fontSize: 16,
                      }}
                      onClick={inCart ? () => navigate('/cart') : addToCartPopup}
                    >
                      {inCart ? "Go to Cart" : "Add to Cart"}
                    </button>
                  )}

                  {(globalState?.subdomain_exists || (course?.discount_price === null && course?.price === null)) && (
                    getcoursestatus === "" ||
                      getcoursestatus == null ||
                      getcoursestatus === "Rejected" ||
                      getcoursestatus === "Visited" ? (
                      <button
                        className="enroll_now_btn"
                        type="button"
                        style={{
                          fontSize: 16,
                        }}
                        onClick={() => {
                          courseRequested(course?.course_id);
                        }}
                        disabled={isEnrolled}
                      >
                        {isEnrolled ? "Request sent" : "Enroll"}
                      </button>
                    ) : (
                      <button
                        className="enroll_now_btn"
                        style={{
                          fontSize: 16,  
                        }}
                        type="button"
                        disabled="true"
                      >
                        {getcoursestatus === "Pending"
                          ? "Request sent"
                          : getcoursestatus}
                      </button>
                    ))
                  }

                  {(globalState?.subdomain_exists || (course?.discount_price === null && course?.price === null)) && getcoursestatus === "Pending" ? (
                    <button
                      className="enroll_now_btn"
                      type="button"
                      style={{
                        fontSize: 14,
                      }}
                      onClick={() => {
                        handleCancelClick();
                      }}
                    >
                      Cancel Request
                    </button>
                  ) : (
                    ""
                  )}
                </div>
                {openDialog && (
                  <div className="Course-created-successfully-main">
                    <div className="Course-created-successfully-container">
                      <div className="Course-created-successfully-folder">
                        <h2>Are you sure you want to cancel the request?</h2>
                        <div className="Course-close-btn">
                          <button type="submit" onClick={cancelRegistration}>
                            No
                          </button>
                          <button
                            type="submit"
                            onClick={() => {
                              cancelrequest(course?.course_id);
                            }}
                            className="Course-close"
                          >
                            Yes
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <div className="view-course-details-container">
                  <div className="view-course-details-folder student-view-course-Detaails-folder">
                    <div className="view-course-details-left-section">
                      <div className="view-course-details-image">
                        <img src={course?.image || course_bg_image} alt="" />
                      </div>
                    </div>
                    <div className="view-course-details-right-section">
                      {/* {!globalState.subdomain_exists && (course?.discount_price !== null && course?.price !== null) && (<div className="card-course-price"><p className="course-price">Course Price : </p>{course?.discount_price != course?.price ? (<p className="course-cut-price">${course?.price}</p>) : ""}{course?.discount_price ? (<span>${course?.discount_price}</span>) : (<span>${course?.price}</span>)}</div>)} */}
                      {!globalState.subdomain_exists && (course?.discount_price !== null && course?.price !== null) && (
                        <div className="card-course-price">
                          <p className="course-price">Course Price: </p>
                          {course?.discount_price !== course?.price && (
                            <p className="course-cut-price">
                              ${Number.isInteger(course?.price) ? course?.price : course?.price.toFixed(2)}
                            </p>
                          )}
                          {course?.discount_price ? (
                            <span>
                              ${Number.isInteger(course?.discount_price) ? course?.discount_price : course?.discount_price.toFixed(2)}
                            </span>
                          ) : (
                            <span>${(course?.price) ? course?.price : course?.price}</span>
                          )}
                        </div>
                      )}
                      <h2>{course?.name}</h2>
                      <div className="teacherviewcoursedetails-des-wrapper">
                        <p
                          className="teacherviewcoursedetails-des"
                          ref={paragraphRef}
                        >
                          {course?.description}
                          {showViewMore && (
                            <span
                              className="view-more"
                              onClick={descriptionToggle}
                            >
                              ...View More
                            </span>
                          )}
                        </p>
                        {descriptionOpen && (
                          <DescriptionCoursePopup
                            title="My Course Description"
                            data={course?.description}
                            onClick={descriptionToggle}
                          />
                        )}
                      </div>
                      <div className="view-course-details-container">
                        <div className="view-Students-course-details-folder">
                          <span>Total Lessons</span>
                          <div className="view-Students-course-details-counts">
                            <p>{course?.total_lessons}</p>
                            <p>
                              {course?.total_lessons > 1 ? "Lessons" : "Lesson"}{" "}
                            </p>
                          </div>
                        </div>
                        <div className="view-Students-course-details-folder">
                          <span>Students Enrolled</span>
                          <div className="view-Students-course-details-counts">
                            <p>{course?.enrolled_students}</p>
                            <p>
                              {course?.enrolled_students > 1
                                ? "Students"
                                : "Student"}{" "}
                            </p>
                          </div>
                        </div>
                        <div className="view-Students-course-details-folder">
                          <span>Course Start Date</span>
                          <div className="view-Students-course-details-counts">
                            <p>
                              {dayjs(course?.start_date).format("MMM DD, YYYY")}{" "}
                            </p>
                          </div>
                        </div>
                        <div className="view-Students-course-details-folder">
                          <span>Course Expiry Date:</span>
                          <div className="view-Students-course-details-counts">
                            <p>
                              {course?.end_date !== null &&
                                course?.end_date !== ""
                                ? dayjs(course?.end_date).format("MMM DD, YYYY")
                                : "- -"}
                            </p>
                          </div>
                        </div>
                        {/* {!globalState.subdomain_exists && <div className="view-Students-course-details-folder">
                          <span>Price</span>
                          <div className="view-Students-course-details-counts">
                            <p>
                              ${course?.discount_price ? course?.discount_price : course?.price}
                            </p>
                          </div>
                        </div>} */}
                      </div>
                    </div>
                  </div>
                </div>
                {loading ? (
                  <LoadingComponent
                    loading_text="Educating the Future, Loading Now."
                  />
                ) :
                  lessons ? (
                    <div className="StudentViewCourseDetails-lesson-wrapper">
                      <h2>Lessons</h2>
                      {
                        (
                          lessons && lessons.length > 0 && (
                            lessons.map((lesson, index) => (
                              <StudentViewCourseLesson
                                id={index}
                                selLession={index === selLession}
                                lesson={lesson}
                                onExpand={() => onExpand(index)}
                              />
                            ))
                          ) 
                        )
                      }
                    </div>
                  ) : (
                    <p className="no-lessons-available">No Lessons Available</p>
                    )
                }
              </div>
            </div>
          </div>
          {showreuestsuccesfully && (
            <div className="Course-created-successfully-main">
              <div className="Course-created-successfully-container">
                <div className="Course-created-successfully-folder">
                  <div className="Course-created-successfully-image">
                    <img src={Course_created_successfully_image} alt="" />
                  </div>
                  <h2>
                    {cancelstatus
                      ? "Course request cancel successfully"
                      : "Course request sent successfully"}
                  </h2>
                </div>
              </div>
            </div>
          )}
        </div>
        {showDropdown && (
          <form className="view-course-details-share-form">
            <div
              className="view-course-details-share-folder"
              data-aos="zoom-in"
            >
              <h2>Share Course</h2>
              <div className="view-course-details-inner-wrapper">
                <p>Share this link via</p>
                <div className="view-course-social-links-folder">
                  <div
                    onClick={() => onShare(getPlatform.WHATSAPP)}
                    className="view-course-social-links-image"
                  >
                    <img src={social_image} alt="" />
                  </div>
                </div>
                <p>Or copy link</p>
                <div className="copy-link-or-path-folder">
                  <input
                    type="text"
                    value={linkBaseUrl}
                    placeholder="example.com/share-link"
                    disabled
                  />
                  <button type="button" onClick={onClickCopy}>
                    Copy
                  </button>
                </div>
                <i
                  onClick={toggleDropdown}
                  className="fa-solid fa-xmark shocial-cross-btn share-course-social-media-btn"
                ></i>
              </div>
            </div>
          </form>
        )}

        {confirmAddtoCart && (
          <Modal
            open={confirmAddtoCart}
            onClose={() => setconfirmAddtoCart(false)}
            center
            showCloseIcon={false}
            classNames={{
              modal: 'Cancel-Popup',
            }}
          >
            <CancelPopup
              title="Are you sure you want to add this course to cart?"
              No="No"
              Yes="Yes"
              show={addToCart} // Assuming you have a state or value to pass
              close={addToCartPopup} // Pass the reference, not the invocation
            />
          </Modal>
        )}
      </div>
    </>
  );
};

export default StudentViewCourseDetails;
