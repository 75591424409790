import React from "react";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { ReactSVG } from "react-svg";
import { useDispatch } from 'react-redux';
import { formatApiErrors } from "../utils/formatApiErrors";
import { google_logo } from "../assets/images/images";
import {
  GoogleAuthProvider,
  auth,
  signInWithPopup,
} from "../config/firebaseConfig";
import api from "../api/api";
import { updateGlobalState, resetGlobalState } from '../state/global/slice.tsx';

const GoogleInUp = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const redirectPath = searchParams.get("redirect");
  if (redirectPath) {
    localStorage.setItem("redirectAfterSignup", redirectPath);
  }
  const redirectLink = localStorage.getItem("redirectAfterSignup");

  const redirectCourse = searchParams.get("redirectToCourse");
  if (redirectCourse) {
    localStorage.setItem("redirectToCourse", redirectCourse);
  }

  const redirectToCoursePath = localStorage.getItem("redirectToCourse");

  const loginWithGoogle = async (e) => {
    e.preventDefault();
    dispatch(resetGlobalState());
    localStorage.removeItem("teacher_formState");
    localStorage.removeItem("student_formState");
    if (props.activeTab === "" || props.activeTab === null) {
      props.setRoleValid(true);
      return;
    }
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;

      if (user) {
        const { email, emailVerified, uid } = user;
        handleUserLogin(email, emailVerified, uid);
      }
    } catch (error) {
      console.error("Error with Google login: ", error);
      toast.error("Login failed. Please try again.", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  const handleUserLogin = async (email, emailVerified, uid) => {
    const failedErrorMessage =
      "Oops, something went wrong. Please try again later.";
    try {
      if (emailVerified) {
        let role = 2;
        if (props.activeTab === "Student") {
          role = 1;
        } else if (props.activeTab === "Teacher") {
          role = 2;
        } else if (props.activeTab === "Parent") {
          role = 3;
        }
        try {
          const hostname = window.location.hostname;
          const subdomain_name = hostname.split(".")[0];
          let subdomain = (subdomain_name == 'proschool' || subdomain_name == 'mycro') ? '' : (subdomain_name == 'www' ? hostname.split(".")[1] : subdomain_name);
          const response = await api.post(`auth/google-login/`, {
            email: email,
            role: role,
            key: uid,
            subdomain:subdomain
          });
          if (response?.status === 400) {
            toast.dismiss();
            const error = formatApiErrors(response?.data);
            toast.error(
              error || response?.data?.message || failedErrorMessage,
              {
                position: toast.POSITION.TOP_CENTER,
              }
            );
            props.setLoading(false);
            return;
          }
          if (response?.data?.token === undefined) {
            toast.dismiss();
            const error = formatApiErrors(response?.data);
            toast.error(
              error || response?.data?.message || failedErrorMessage,
              {
                position: toast.POSITION.TOP_CENTER,
              }
            );
            props.setLoading(false);
            return;
          }
          props.login(response?.data?.token, props.activeTab, email || "");
          toast.dismiss();
          toast.success("Login successful.", {
            position: toast.POSITION.TOP_CENTER,
          });
          if (props.activeTab === "Student") {
            // if (redirectLink !== null && redirectLink !== "") {
            //   console.log("Student redierct", response?.data)
            //   navigate(redirectLink);
            //   //localStorage.removeItem("redirectAfterSignup");
            //   return;
            // }
            // if (redirectToCoursePath !== null && redirectToCoursePath !== "") {
            //   console.log("Student coursepath", response?.data)
            //   navigate(redirectToCoursePath);
            //   // localStorage.removeItem("redirectToCourse");
            //   return;
            // }
            // const { pathname } = location.state || { from: "/dashboard/home" };
            // navigate(pathname || "/dashboard/home", {
            //   state: { role: props.activeTab },
            // });
            if (response?.data?.subdomain && response?.data?.subdomain !== 'none' && response?.data?.subdomain !== 'null' && response?.data?.subdomain !== 'undefined') {
            
              if (redirectLink !== null && redirectLink !== "") {
                  navigate(redirectLink);
                  return;
              }
              if (redirectToCoursePath !== null && redirectToCoursePath !== "") {
                  navigate(redirectToCoursePath);
                  return;
              }
                const path = `${response?.data?.subdomain}/dashboard/home`;
                const emaildtl = encodeURIComponent(email);  // Ensure 'email' is defined in your scope
                const role = encodeURIComponent(props.activeTab);
                const fullUrl = `${path}?email=${emaildtl}&redirectedrole=${role}`;
                window.location.href = fullUrl;

                return;
            } else {
              // if (redirectLink !== null && redirectLink !== "") {
              //   navigate(redirectLink);
              //   return;
              // }
              // if (redirectToCoursePath !== null && redirectToCoursePath !== "") {
              //   navigate(redirectToCoursePath);
              //   return;
              // }
              // const { pathname } = location.state || { from: "/dashboard/home" };
              // navigate(pathname || "/dashboard/home", {
              //   state: { role: props.activeTab },
              // });
              
              if (response?.data?.is_profile === true) {
                if (redirectLink !== null && redirectLink !== "") {
                  navigate(redirectLink);
                  return;
                }
                if (redirectToCoursePath !== null && redirectToCoursePath !== "") {
                  navigate(redirectToCoursePath);
                  return;
                }
              }
              else if (response?.data?.is_profile === false) {
                navigate("/dashboard/home", {
                  state: { role: props.activeTab },
                });
              }

            }
          } else if (props.activeTab === "Teacher") {
            // navigate("/dashboard/home", { state: { role: props.activeTab } });
            if (response?.data?.subdomain && response?.data?.subdomain !== 'none' && response?.data?.subdomain !== 'null' && response?.data?.subdomain !== 'undefined') {
              const path = `${response?.data?.subdomain}/dashboard/home`;
              const emaildtl = encodeURIComponent(email);
              const role = encodeURIComponent(props.activeTab);
              const fullUrl = `${path}?email=${emaildtl}&redirectedrole=${role}`;
              
              window.location.href = fullUrl;
            } else {
                navigate("/dashboard/home", { state: { role: props.activeTab } });
            }     
          } else if (props.activeTab === "Parent") {
            role = "parent";
          }
          props.setLoading(false);
        } catch (error) {
          toast.dismiss();
          const errorMessage = formatApiErrors(error?.response?.data);
          toast.error(errorMessage || failedErrorMessage, {
            position: toast.POSITION.TOP_CENTER,
          });
          props.setLoading(false);
        }
      }
    } catch (error) {
      console.error("Error fetching user info", error);
    }
  };
  return (
    <>
      <button
        disabled={props.loading}
        className="login-btns"
        onClick={loginWithGoogle}
      >
        <ReactSVG src={google_logo} />
        <p>{props.forContent}</p>
      </button>
    </>
  );
};

export default GoogleInUp;
